import React from "react";

const PopupIcon = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20 21.6666C22.7614 21.6666 25 19.428 25 16.6666C25 13.9052 22.7614 11.6666 20 11.6666C17.2386 11.6666 15 13.9052 15 16.6666C15 19.428 17.2386 21.6666 20 21.6666Z"
        stroke="#63A6FD"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.0003 3.33331C16.4641 3.33331 13.0727 4.73807 10.5722 7.23856C8.07175 9.73904 6.66699 13.1304 6.66699 16.6666C6.66699 19.82 7.33699 21.8833 9.16699 24.1666L20.0003 36.6666L30.8337 24.1666C32.6637 21.8833 33.3337 19.82 33.3337 16.6666C33.3337 13.1304 31.9289 9.73904 29.4284 7.23856C26.9279 4.73807 23.5365 3.33331 20.0003 3.33331V3.33331Z"
        stroke="#63A6FD"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.0003 3.33331C16.4641 3.33331 13.0727 4.73807 10.5722 7.23856C8.07175 9.73904 6.66699 13.1304 6.66699 16.6666C6.66699 19.82 7.33699 21.8833 9.16699 24.1666L20.0003 36.6666L25.417 30.4166L30.8337 24.1666C32.6637 21.8833 33.3337 19.82 33.3337 16.6666C33.3337 13.1304 31.9289 9.73904 29.4284 7.23856C26.9279 4.73807 23.5365 3.33331 20.0003 3.33331Z"
        stroke="#63A6FD"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20 21.6666C22.7614 21.6666 25 19.4281 25 16.6666C25 13.9052 22.7614 11.6666 20 11.6666C17.2386 11.6666 15 13.9052 15 16.6666C15 19.4281 17.2386 21.6666 20 21.6666Z"
        stroke="#63A6FD"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export default PopupIcon;
