import React from "react";

const BackIcon = () => {
  return (
    <svg width="20" height="20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.25 10a8.75 8.75 0 1 0 17.5 0 8.75 8.75 0 0 0-17.5 0ZM20 10a10 10 0 1 1-20 0 10 10 0 0 1 20 0Zm-5.625-.625a.625.625 0 1 1 0 1.25H7.134l2.684 2.682a.626.626 0 1 1-.886.886l-3.75-3.75a.625.625 0 0 1 0-.886l3.75-3.75a.626.626 0 1 1 .886.885L7.134 9.375h7.241Z"
        fill="#63A6FD"
      />
    </svg>
  );
};

export default BackIcon;
