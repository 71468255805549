import React, {useEffect, useState, useRef} from "react";
import useAuth from "../Hooks/useAuth";
import LoginIcon from "../Media/Icons/Login";
import "../Style/login.css";
import useRefreshToken from "../Hooks/useRefreshToken";
import {useNavigate} from "react-router-dom";
import {useLocation} from "react-router-dom";
import useNotifications from "../Hooks/useNotifications";
import useLogout from "../Hooks/useLogout";

function LoginButton({isSidebarOpen}) {
  const ref = useRef();
  const [showPop, setShowPop] = useState(false);
  const {auth, authData} = useAuth();
  const {setMessage} = useNotifications();
  const refresh = useRefreshToken();
  const navigate = useNavigate();
  const location = useLocation().pathname;

  const logout = useLogout();

  useEffect(() => {
    setShowPop(false);
  }, [location, isSidebarOpen]);

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      // If the login popover is open and the clicked target is not within the popover,
      // then close the popover
      if (showPop && ref.current && !ref.current.contains(e.target)) {
        setShowPop(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [showPop]);

  const verifyRefreshToken = async () => {
    try {
      await refresh();
    } catch (error) {
      return false;
    }

    return true;
  };

  const handleClick = async () => {
    if (!showPop) {
      if (auth?.access && authData?.is_staff) {
        setShowPop((prev) => !prev);
      } else {
        /* Try to refresh token */
        const activeToken = await verifyRefreshToken();
        //activeToken ? console.log("token refreshed") : console.log("no refresh token!");
        activeToken ? setShowPop(true) : navigate("login");
      }
    } else {
      setShowPop(false);
    }
  };

  const handleLogOut = async () => {
    try {
      logout();
      setShowPop(false);
    } catch (error) {
      setMessage(error);
    }
  };

  return (
    <>
      <button
        className={`${
          location === "/login" ? "active" : ""
        } login-position w-full px-6 pt-7 pb-11 bg-blue-400 relative z-20`}
        onClick={handleClick}
      >
        <LoginIcon />
      </button>
      <div
        ref={ref}
        className={`${
          showPop && isSidebarOpen ? "visible" : ""
        } login-popover pt-3 pb-4 bg-blue-400 text-center relative z-10`}
      >
        <p className="text-gray-100 leading-none text-sm">Logged in as</p>
        <p className="text-blue-100 text-base mb-2 h-6">{authData?.username}</p>
        <button className="btn-logout text-white text-sm px-4 py-1 mb-1 border border-solid border-blue-100 hover:bg-blue-200 hover:border-blue-200">
          <a href="https://wesea-dev.listlabs.net/admin/">Go to Admin</a>
        </button>
        <button
          className="btn-logout text-white text-sm px-4 py-1 bg-blue-100 hover:bg-blue-200"
          onClick={handleLogOut}
        >
          Log out
        </button>
      </div>
    </>
  );
}

export default LoginButton;
