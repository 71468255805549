import React from "react";

const LoginIcon = () => {
  return (
    <svg width="30" height="30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15 2.5C8.096 2.5 2.5 8.096 2.5 15S8.096 27.5 15 27.5 27.5 21.904 27.5 15 21.904 2.5 15 2.5Z"
        stroke="#5176A5"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.339 22.933S8.125 19.375 15 19.375s9.663 3.558 9.663 3.558M15 15a3.75 3.75 0 1 0 0-7.5 3.75 3.75 0 0 0 0 7.5v0Z"
        stroke="#5176A5"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default LoginIcon;
