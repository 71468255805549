import React from "react";
import SunnyIcon from "../Media/Icons/sunny_icon";
import CloudIcon from "../Media/Icons/cloud_icon";
import "../Style/header.css";

const MobileDates = (props) => {
  return (
    <button
      className={`dates-mobile-child ${props.isSelected ? "date-mobile-selected" : ""}`}
      onClick={(e) => props.handleClick(e, props.index)}
      disabled={props.date === "--/--/----"}
    >
      {props.type === "cloudy" ? (
        <CloudIcon id={props.date} />
      ) : (
        <SunnyIcon id={props.date} />
      )}
      {props.date}
    </button>
  );
};

export default MobileDates;
