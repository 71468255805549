import React from "react";

const LogoIcon = () => {
  return (
    <svg width="40" height="40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.154 31.304h26.747c1.656-1.688 2.594-4.14 2.857-5.155-8.626.149-26.27.177-27.99 0-1.614-.167-2.974-1.793-3.222-2.24-.586-1.052-.88-2.537-.207-3.897.806-1.63 1.933-2.232 3.43-2.232s3.857.609 4.147 3.653c.29 3.045 3.726 2.801 4.885 1.99 2.658-1.862.484-6.415-.786-8.038-1.112-1.421-3.271-3.735-8.246-4.175-3.74-.33-8.647 2.104-10.179 6.57-2.608 8.369 4.01 13.118 8.564 13.524Z"
        fill="#4A92F0"
      />
      <path
        d="M34.12 33.458H5.714c4.105 3.322 7.215 5.631 14.887 5.631 6.137 0 11.57-3.754 13.519-5.631Z"
        fill="#1E75EE"
        stroke="#1E75EE"
      />
      <path
        d="M15.217 4.33C6.485 3.878 2.207 9.923 1.16 13.003c.794-.908 1.282-1.459 2.688-2.31 9.551-5.065 15.816 1.642 16.952 3.364 1.867 2.83 2.033 4.288 2.28 7.124.342 3.897 6.527 3.978 7.106.372.463-2.885-.469-6.2-.992-7.496-1.02-3.053-5.243-9.272-13.976-9.725Z"
        fill="#7CB1F5"
      />
      <ellipse cx="10.656" cy="21.615" rx="1.656" ry="1.615" fill="#D85E5E" />
      <path
        d="M17.142.144c-3.114.324-6.376 1.97-7.619 2.752 3.064-.607 3.768-.93 8.447-.324 3.743.486 7.301 3.035 8.612 4.25 1.574 1.348 4.565 5.05 5.383 8.821.641 2.955.166 3.076.497 5.625.302 2.324 2.608 2.914 3.685 2.914s3.503-.647 3.768-2.913c.265-2.267-.166-4.573-.29-5.383-.118-.769-.638-3.286-2.857-6.88-2.22-3.593-5.672-5.705-6.707-6.312C27.7 1.277 25.023.576 23.643.306c-.87-.189-3.387-.486-6.501-.162Z"
        fill="#ACCDFB"
      />
    </svg>
  );
};

export default LogoIcon;
