import React from "react";

const Marker = ({markercolor}) => {
  return (
    <svg width="22" height="33" fill={markercolor} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11 16.75c1.239-.001 2.422-.53 3.293-1.462.87-.931 1.356-2.191 1.357-3.502 0-.978-.27-1.936-.78-2.752a4.731 4.731 0 0 0-2.085-1.832 4.378 4.378 0 0 0-2.696-.284 4.568 4.568 0 0 0-2.383 1.364 5.077 5.077 0 0 0-1.267 2.538 5.278 5.278 0 0 0 .263 2.86 4.91 4.91 0 0 0 1.708 2.229 4.445 4.445 0 0 0 2.59.841Zm0 15.86a66.101 66.101 0 0 1-1.52-1.933 73.966 73.966 0 0 1-3.768-5.47C2.964 20.79.25 15.245.25 10.607.25 4.898 5.057.25 11 .25s10.75 4.648 10.75 10.357c0 4.638-2.714 10.183-5.462 14.6a73.942 73.942 0 0 1-5.01 7.06 45.92 45.92 0 0 1-.278.342Z"
        fill={markercolor}
        stroke="#fff"
        strokeWidth=".5"
      />
    </svg>
  );
};

export default Marker;
