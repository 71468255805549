import React from "react";

const Help = () => {
  return (
    <svg width="40" height="40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="20" cy="20" r="20" fill="#5176A5" />
      <path
        d="M25.834 16.5a5.833 5.833 0 0 0-11.667 0 1.167 1.167 0 1 0 2.333 0A3.5 3.5 0 1 1 20 20a1.167 1.167 0 0 0-1.166 1.166v2.333a1.167 1.167 0 1 0 2.333 0v-1.283a5.833 5.833 0 0 0 4.667-5.717ZM20 29.333A1.167 1.167 0 1 0 20 27a1.167 1.167 0 0 0 0 2.333Z"
        fill="#fff"
      />
    </svg>
  );
};

export default Help;
