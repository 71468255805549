import React from "react";
import OSMicon from "../Media/Images/OSMicon.png";
import DOFicon from "../Media/Images/DOFicon.png";
import incidentsIcon from "../Media/Images/incidents.png";
import imageryIcon from "../Media/Images/imagery.png";
import "../Style/map.css";

const BaseMapIcon = (props) => {
  const getIcon = () => {
    switch (props.name.toLowerCase()) {
      case "openstreetmap":
        return OSMicon;
      case "dof":
        return DOFicon;
      case "detections":
        return incidentsIcon;
      case "imagery":
        return imageryIcon;
      default:
        return OSMicon;
    }
  };

  const handleClick = () => {
    props.clickHandler(props.layerObj);
  };

  return (
    <div
      className={`base-layer-icon base-layer-${props.name.toLowerCase()} layer-${
        props.layerObj.checked ? "active" : "inactive"
      }`}
    >
      <img
        className={`base-layer-image base-layer-${props.name.toLowerCase()}`}
        src={getIcon()}
        alt={props.name}
        onClick={handleClick}
      />
      <div className={`base-layer-text base-layer-${props.name.toLowerCase()}`}>
        {props.name}
      </div>
      <div className="vertical-line"></div>
    </div>
  );
};

export default BaseMapIcon;
