import React from "react";

const Step1Background = () => {
  return (
    <svg width="723" height="420" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 20C0 8.954 8.954 0 20 0h680c11.046 0 20 8.954 20 20v400H0V20Z"
        fill="#022859"
      />
      <circle cx="362" cy="190" r="110" fill="#D9D9D9" fillOpacity=".05" />
      <path
        d="M227.408 297.135C128.887 297.135 90.574 359.509 3 419h719V252c-32.166 0-85.5 4.969-134.844 45.135-76.627 62.374-117.926 79.738-174.941 62.374-65.828-20.047-80.814-62.374-184.807-62.374Z"
        fill="#4A92F0"
        fillOpacity=".1"
        stroke="#4A92F0"
        strokeOpacity=".1"
      />
      <path
        d="M239 340.35c-110.654 0-167.704 23.748-237 78.65h720V122c-28.039 0-59.082 4.037-104.145 46.712C566.256 217.578 538.598 277.201 468 310.65c-56.478 26.759-161.406 29.7-229 29.7Z"
        fill="#4A92F0"
        fillOpacity=".1"
        stroke="#4A92F0"
        strokeOpacity=".1"
      />
      <g filter="url(#a)">
        <path
          d="M324.039 231.564h93.615c5.797-5.91 9.082-14.492 9.999-18.044-30.19.521-91.947.621-97.962 0-5.652-.583-10.411-6.275-11.28-7.838-2.051-3.686-3.078-8.882-.725-13.64 2.822-5.707 6.765-7.815 12.005-7.815 5.24 0 13.5 2.131 14.515 12.788 1.014 10.656 13.042 9.803 17.099 6.962 9.301-6.514 1.693-22.449-2.753-28.133-3.89-4.973-11.448-13.071-28.861-14.611-13.092-1.157-30.264 7.365-35.626 22.994-9.13 29.293 14.034 45.917 29.974 47.337Z"
          fill="#4A92F0"
        />
        <path
          d="M411.42 239.102h-99.421c14.369 11.627 25.254 19.71 52.105 19.71 21.481 0 40.495-13.14 47.316-19.71Z"
          fill="#1E75EE"
          stroke="#1E75EE"
        />
        <path
          d="M345.261 137.159c-30.564-1.589-45.537 19.572-49.203 30.35 2.778-3.177 4.486-5.106 9.406-8.084 33.43-17.728 55.358 5.745 59.334 11.772 6.536 9.904 7.114 15.01 7.983 24.937 1.192 13.637 22.842 13.922 24.868 1.3 1.621-10.098-1.64-21.699-3.473-26.237-3.57-10.685-18.35-32.45-48.915-34.038Z"
          fill="#73B0FF"
        />
        <ellipse cx="329.297" cy="197.652" rx="5.797" ry="5.652" fill="#D85E5E" />
        <path
          d="M351.997 122.504c-10.898 1.133-22.317 6.893-26.665 9.632 10.724-2.125 13.188-3.258 29.564-1.133 13.101 1.699 25.554 10.623 30.143 14.872 5.507 4.721 15.975 17.678 18.84 30.877 2.244 10.34.579 10.765 1.739 19.688 1.056 8.132 9.13 10.198 12.898 10.198 3.768 0 12.26-2.266 13.187-10.198.928-7.931-.579-16.005-1.014-18.838-.413-2.691-2.232-11.501-10-24.079-7.767-12.577-19.854-19.971-23.477-22.095-8.26-4.958-17.632-7.413-22.462-8.357-3.044-.661-11.855-1.7-22.753-.567Z"
          fill="#ACCDFB"
        />
      </g>
      <defs>
        <filter
          id="a"
          x="292"
          y="117"
          width="157"
          height="147.312"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="12" />
          <feGaussianBlur stdDeviation="2.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.05 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_2419_3715" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow_2419_3715" result="shape" />
        </filter>
      </defs>
    </svg>
  );
};

export default Step1Background;
