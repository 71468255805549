import React from "react";

const ArticleIcon = ({selectedArea, isMediaActive}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        className={`${
          !selectedArea?.id
            ? "fill-[#bbb]"
            : `${isMediaActive ? "fill-white" : "fill-black group-hover:fill-white"} ""`
        }`}
        d="M19 5v14H5V5h14Zm0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2Z"
      />
      <path
        className={`${
          !selectedArea?.id
            ? "fill-[#bbb]"
            : `${isMediaActive ? "fill-white" : "fill-black group-hover:fill-white"} ""`
        }`}
        d="M14 17H7v-2h7v2Zm3-4H7v-2h10v2Zm0-4H7V7h10v2Z"
      />
    </svg>
  );
};

export default ArticleIcon;
