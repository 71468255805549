import React from "react";
import "../Style/map.css";
import OSMicon from "../Media/Images/OSMicon.png";
import DOFicon from "../Media/Images/DOFicon.png";

const MapLayerIcon = (props) => {
  const getIcon = () => {
    switch (props.base.toLowerCase()) {
      case "osm":
        return OSMicon;
      case "dof":
        return DOFicon;
      default:
        return OSMicon;
    }
  };

  return (
    <div className="base-layer-icon">
      <img src={getIcon()} alt="" />
    </div>
  );
};

export default MapLayerIcon;
