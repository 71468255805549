import React from "react";
import ForwardIcon from "../Media/Icons/forward_icon";
import BackIcon from "../Media/Icons/back_icon";
import {useEffect} from "react";
import useSelectedDate from "../Hooks/useSelectedDate";

const CarouselButtons = ({next, previous, ...rest}) => {
  const {
    fetchNewAvailableDates,
    setFetchNewAvailableDates,
    selectedDate,
    availableDates,
  } = useSelectedDate();
  let {
    carouselState: {currentSlide, totalItems, slidesToShow},
  } = rest;

  useEffect(() => {
    // On initial render, go to the selected date inside carousel
    // If location is not selected, show date placeholder
    if (!selectedDate) {
      rest.goToSlide(0);
    } else if (
      selectedDate &&
      fetchNewAvailableDates.indexOfSelectedDate <= slidesToShow
    ) {
      rest.goToSlide(0);
    } else {
      rest.goToSlide(fetchNewAvailableDates.indexOfSelectedDate - slidesToShow);
    }
  }, []);

  useEffect(() => {
    // On new dates fetch show carousel so that selected date is visible inside carousel
    if (fetchNewAvailableDates.indexOfSelectedDate <= slidesToShow) {
      rest.goToSlide(0);
    } else {
      rest.goToSlide(fetchNewAvailableDates.indexOfSelectedDate - slidesToShow);
    }
  }, [availableDates]);

  const handleBackwardClick = () => {
    // Triggering useEffect in selectedDateProvider
    if (currentSlide === 0) {
      setFetchNewAvailableDates((prev) => {
        return {
          ...prev,
          pagination: prev.pagination + 1,
          fetchDirection: "backward",
        };
      });
    } else {
      previous();
    }
  };

  const handleForwardClick = () => {
    // Triggering useEffect in selectedDateProvider
    if (currentSlide === totalItems - slidesToShow) {
      setFetchNewAvailableDates((prev) => {
        return {
          ...prev,
          pagination: prev.pagination - 1,
          fetchDirection: "forward",
        };
      });
    } else {
      next();
    }
  };

  return (
    <div className="arrows">
      <button
        className={`${
          currentSlide === 0 && !fetchNewAvailableDates.backward
            ? "arrow-disable"
            : "arrow-child"
        } bg-white hover:bg-blue-100`}
        onClick={handleBackwardClick}
        disabled={currentSlide === 0 && !fetchNewAvailableDates.backward}
      >
        <BackIcon />
      </button>
      <div className="gap"></div>
      <button
        className={`${
          currentSlide === totalItems - slidesToShow && !fetchNewAvailableDates.forward
            ? "arrow-disable"
            : "arrow-child"
        } bg-white hover:bg-blue-100`}
        onClick={handleForwardClick}
        disabled={
          currentSlide === totalItems - slidesToShow && !fetchNewAvailableDates.forward
        }
      >
        <ForwardIcon />
      </button>
    </div>
  );
};

export default CarouselButtons;
