import React from "react";

const InfoMap = () => {
  return (
    <svg width="120" height="107" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M115.616 10.41 78.4 0 41.075 10.514 6.365.667A5 5 0 0 0 0 5.477v85.15A6.023 6.023 0 0 0 4.362 96.4l36.704 10.412 37.342-10.518 35.246 9.859a5.002 5.002 0 0 0 5.825-2.592 5.008 5.008 0 0 0 .521-2.225V16.188a6.018 6.018 0 0 0-4.384-5.778ZM36.75 97.271 8 89.115V9.447l28.75 8.156v79.668Zm37.654-8.162L44.75 97.462V17.79l29.654-8.353v79.672ZM112 97.382l-29.596-8.278V9.427L112 17.704v79.678Z"
        fill="#63A6FD"
      />
    </svg>
  );
};

export default InfoMap;
