import React from "react";

const MapIcon = () => {
  return (
    <svg width="24" height="22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M23.123 2.082 15.68 0 8.215 2.103 1.273.133A1 1 0 0 0 0 1.096v17.03a1.205 1.205 0 0 0 .872 1.154l7.341 2.082 7.469-2.103 7.049 1.972A1 1 0 0 0 24 20.267V3.237a1.204 1.204 0 0 0-.877-1.155ZM7.35 19.454 1.6 17.823V1.889l5.75 1.632v15.933Zm7.53-1.632-5.93 1.67V3.558l5.93-1.67v15.934Zm7.52 1.654-5.92-1.655V1.885l5.92 1.656v15.935Z"
        fill="#5C81B0"
      />
    </svg>
  );
};

export default MapIcon;
