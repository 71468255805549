import axios from "../Services/axios";
import useAuth from "./useAuth";

const useLogout = () => {
  const {setAuthData, setAuth, setPersist} = useAuth();

  const logout = async () => {
    setAuth({});
    try {
      const response = await axios.post("/user/token/logout/", {
        withCredentials: true,
      });
      if (response) {
        setAuthData({});
        setAuth({});
        setPersist(false);
        if (sessionStorage.getItem("accessToken"))
          sessionStorage.removeItem("accessToken");
      }
    } catch (error) {
      //console.error(error);
    }
  };

  return logout;
};

export default useLogout;
