import React from "react";

const JoyRideHelpIcon = () => {
  return (
    <svg width="60" height="60" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="30" cy="30" r="30" fill="#5176A5" />
      <path
        d="M38.75 24.75a8.75 8.75 0 0 0-17.5 0 1.75 1.75 0 0 0 3.5 0A5.25 5.25 0 1 1 30 30a1.75 1.75 0 0 0-1.75 1.75v3.5a1.75 1.75 0 0 0 3.5 0v-1.925a8.75 8.75 0 0 0 7-8.575ZM30 44a1.75 1.75 0 1 0 0-3.5 1.75 1.75 0 0 0 0 3.5Z"
        fill="#fff"
      />
    </svg>
  );
};

export default JoyRideHelpIcon;
