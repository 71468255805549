import React, {createContext, useState, useEffect} from "react";
import jwtDecode from "jwt-decode";
import useRefreshToken from "../Hooks/useRefreshToken";

const AuthContext = createContext({});

export const AuthProvider = ({children}) => {
  const [auth, setAuth] = useState({});
  const [persist, setPersist] = useState(
    JSON.parse(localStorage.getItem("persist")) || false
  );
  const [authData, setAuthData] = useState({});

  const refresh = useRefreshToken();

  useEffect(() => {
    if (auth.access) {
      try {
        let decode = jwtDecode(auth.access);
        setAuthData(decode);
      } catch (error) {
        setAuthData({});
      }
    } else if (authData?.is_staff) {
      setAuthData({});
    }
  }, [auth]);

  useEffect(() => {
    const sessionToken = JSON.parse(sessionStorage.getItem("accessToken"));
    if (!auth && !sessionToken) {
      try {
        refresh();
      } catch {
        // console.log("errored")
      }
    } else if (sessionToken) {
      setAuth({access: sessionToken});
    }

    return () => {
      setAuth({});
    };
  }, []);

  return (
    <AuthContext.Provider
      value={{auth, setAuth, persist, setPersist, authData, setAuthData}}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
