import React from "react";
import "../Style/popup.css";
import PopupIcon from "../Media/Icons/popup_icon";

const IncidentPopup = (props) => {
  let statusColor;
  if (props?.feature?.properties?.colour) {
    statusColor = props.feature.properties.colour;
  }

  const statusColorStyle = {color: statusColor};
  return (
    <>
      <div className="popup-wrapper-heading font-roboto">
        <div className="popup-left-side">
          <div className="popup-dates-style">Reference date</div>
          <div>{props?.feature?.reference_date}</div>
        </div>
        <div className="popup-right-side">
          <div className="popup-dates-style">Target date</div>
          <div>{props?.feature?.date}</div>
        </div>
      </div>
      <div className="marker-icon">
        <PopupIcon />
      </div>
      <div className="title-popup font-roboto">{props?.area?.name}</div>
      <div className="popup-text-wrapper font-roboto">
        {/* <div className="popup-text-child popup-row-color">
          <div className="popup-left-text">ID</div>
          <div className="popup-right-text">{props?.vector?.id}</div>
        </div> */}
        <div className="popup-text-child popup-row-color">
          <div className="popup-left-text">Area</div>
          <div className="popup-right-text">
            {Math.trunc(props?.vector?.area)} m&sup2;
          </div>
        </div>
        {/* <div className="popup-text-child">
          <div className="popup-left-text">Class</div>
          <div className="popup-right-text">
            {props?.feature?.properties?.detection_class}
          </div>
        </div>

        <div className="popup-text-child">
          <div className="popup-left-text">Probability</div>
          <div className="popup-right-text">{props?.vector?.probability}&#37;</div>
        </div>
        <div className="popup-text-child popup-row-color">
          <div className="popup-left-text">Status</div>
          <div className="popup-right-text" style={statusColorStyle}>
            {props?.vector?.status}
          </div>
        </div> */}
      </div>
    </>
  );
};

export default IncidentPopup;
