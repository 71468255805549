import React from "react";
import CloudIcon from "../Media/Icons/cloud_icon";
import SunnyIcon from "../Media/Icons/sunny_icon";
import "../Style/header.css";

const CarouselDate = (props) => {
  return (
    <div
      key={props.date}
      className={`dates-child dates-child-tablet ${
        props.isSelected ? "date-selected" : ""
      }`}
      onClick={(e) => props.handleClick(e, props.index)}
    >
      {props.type === "cloudy" ? (
        <CloudIcon id={props.date} />
      ) : (
        <SunnyIcon id={props.date} />
      )}
      <p className="noselect">{props.date}</p>
    </div>
  );
};

export default CarouselDate;
