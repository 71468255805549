import React from "react";

const Button = (props) => {
  return (
    <button
      {...props}
      className={`${props.buttonclass} ${props.title === "hidden" ? "hidden" : ""}`}
    >
      {props.title}
    </button>
  );
};

export default Button;
