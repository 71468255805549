import React, {useState, useEffect, useCallback} from "react";
import L from "leaflet";
import axios from "../Services/axios";
import Select from "react-select";
import {useSwipeable} from "react-swipeable";
import useNotifications from "../Hooks/useNotifications";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import "../Style/header.css";

import CarouselButtons from "./CarouselButtons";
import mainSelectStyle from "../Utils/mainSelectStyle";
import CarouselDate from "./CarouselDate";
import MobileDates from "./MobileDates";
import MobileCarouselButtons from "./MobileCarouselButtons";
import useSelectedDate from "../Hooks/useSelectedDate";
import useIncidentsLayer from "../Hooks/useIncidentsLayer";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: {max: 4000, min: 3000},
    items: 8,
  },
  desktop1: {
    breakpoint: {max: 1920, min: 1400},
    items: 6,
  },
  desktop: {
    breakpoint: {max: 1400, min: 1201},
    items: 5,
  },
  desktopSmall: {
    breakpoint: {max: 1201, min: 1000},
    items: 3,
  },
  tablet: {
    breakpoint: {max: 1000, min: 850},
    items: 2,
  },
  mobile: {
    breakpoint: {max: 850, min: 0},
    items: 1,
  },
};

const datePlaceholder = "--/--/----";

const Header = (props) => {
  const {
    selectedDate,
    setSelectedDate,
    referenceDate,
    availableDates,
    selectedArea,
    setSelectedArea,
    availableAreas,
    mobileDatesRange,
    setMobileDatesRange,
    setFetchNewAvailableDates,
  } = useSelectedDate();
  const {setLayerData} = useIncidentsLayer();
  const {appState, setAppState} = useNotifications();
  const [collapsed, setCollapsed] = useState(true);

  const clickHandlerMobile = () => {
    setCollapsed((prev) => !prev);
  };

  // ! NEED DISCUSSION

  /* useEffect(() => {
    // If coming back from i.e. Dashboard
    if (props.mapRef?.target) {
      if (selectedArea?.geom && selectedDate?.date) {
        // console.log("Returning to bounds");
        // Re fetch incidents when retruning to map
        // console.log("Fetch incidents again");
        // if (!selectedDate?.date) {
        //   setSelectedDate(referenceDate);
        // }
      } else if (referenceDate?.date) {
        // On first render

        //let features = fetchIncidents();

        if (props.mapRef?.target && selectedArea?.geom) {
          // Making leaflet polygon based on selected area coordinates
          let polygonBounds = L.polygon([selectedArea.geom.coordinates[0]]);
          // Getting center of the polygon (object)
          let polygonCenterObj = polygonBounds.getBounds().getCenter();
          // Converting object into array and reversing array values
          let polygonCenterArr = Object.values(polygonCenterObj).reverse();

          // Fly to selected area and set map view to the center of the polygon at 16 zoom level
          props.mapRef?.target.flyTo(polygonCenterArr, 16);
          props.mapRef?.target.once("moveend zoomend", async () => {
            setSelectedDate(referenceDate);
            let guideFinished = localStorage.getItem("mapGuideFinished");
            if (!(guideFinished === "true")) {
              setAppState((prev) => {
                return {...prev, stepIndex: 2, run: true && prev.enabled};
              });
            }
          });
        }
      }
    }
    return () => {
      setAppState((prev) => {
        return {...prev, run: false, tourActive: false, stepIndex: 0};
      });
      props.mapRef?.target?.eachLayer(function (layer) {
        if (layer instanceof L.Marker) {
          props.mapRef?.target?.removeLayer(layer);
        }
      });
    };
  }, [selectedArea]); */

  const dateClickHandle = (e, idx) => {
    let newDate = e.currentTarget?.innerText;
    if (newDate !== datePlaceholder) {
      let filterSeletedDate = availableDates.filter((availableDate) => {
        return availableDate.date === newDate;
      });
      setSelectedDate(filterSeletedDate[0]);

      // setting index of selected date so we can display selected date inside carousel
      // for example, if you go from info page back to map page, carousel will  display at index of selected date
      setFetchNewAvailableDates((prev) => {
        return {
          ...prev,
          indexOfSelectedDate: filterSeletedDate[0].index,
        };
      });
    }
  };

  const handleSelectArea = (selected) => {
    /* 
      /* This if statement checks if map guide is in the process and if location is selected 
      /* If you select area in dashboard and go to map, selected area stays in global state so we automatically have selected area in map page
      /* When we have selected area, we need to handle step 2 (index 1) of guidance so that when we click on selected 
          location that is already selected we go to next step of guide
    */
    if (selectedArea.id && !appState.run && appState.tourActive) {
      setAppState((prev) => {
        return {...prev, stepIndex: 2, run: true};
      });
    }

    if (selectedArea.id === selected.value.id) {
      return;
    } else {
      setSelectedDate("");
      setSelectedArea(selected.value);
      // Triggers useEffect in SelectedDateProvider
    }
  };

  const handlersMenu = useSwipeable({
    onSwipedUp: () => {
      setCollapsed(false);
    },
    onSwipedDown: () => {
      setCollapsed(true);
    },
  });

  /* const handlersDates = useSwipeable({
    onSwipedLeft: () => handleMobileDatesChange(true),
    onSwipedRight: () => handleMobileDatesChange(false),
  }); */

  const handleMenuOpen = () => {
    if (appState.stepIndex === 1 && appState.run) {
      setAppState((prev) => {
        return {
          ...prev,
          run: false,
        };
      });
    }
  };

  // Showing 4 mobile dates in layout
  // When you come to the end of array, and there are less than 4 dates, then rest of layout is filled with date placeholder
  const handleMobileDates = () => {
    if (mobileDatesRange.start + mobileDatesRange.end >= 4) {
      const showAvailableDates = availableDates
        .slice(mobileDatesRange.start, mobileDatesRange.end)
        .map((element, idx) => {
          return (
            <MobileDates
              key={`date-${idx}`}
              type={"sunny"}
              date={element.date}
              index={idx}
              handleClick={dateClickHandle}
              isSelected={element?.date === selectedDate?.date}
              range={mobileDatesRange}
            />
          );
        });
      return showAvailableDates;
    } else {
      const restOfTheDates = availableDates
        .slice(mobileDatesRange.start, mobileDatesRange.end)
        .map((element, idx) => {
          return (
            <MobileDates
              key={`date-${idx}`}
              type={"sunny"}
              date={element.date}
              index={idx}
              handleClick={dateClickHandle}
              isSelected={element?.date === selectedDate?.date}
              range={mobileDatesRange}
            />
          );
        });
      let placeholderFill = [
        {
          date: datePlaceholder,
        },
        {
          date: datePlaceholder,
        },
        {
          date: datePlaceholder,
        },
        {
          date: datePlaceholder,
        },
      ];
      placeholderFill.forEach((element, i) => {
        element.index = i;
      });

      let slicedPlaceholder = placeholderFill.slice(restOfTheDates.length);
      for (let placeholder of slicedPlaceholder) {
        restOfTheDates.unshift(
          <MobileDates key={placeholder.index} date={placeholder.date} />
        );
      }
      return restOfTheDates;
    }
  };

  return (
    <>
      <div className="wrapper-header font-roboto">
        <div className="carousel-section">
          <div className="dates" disabled={!selectedArea?.name ? true : false}>
            <Carousel
              responsive={responsive}
              showDots={false}
              slidesToSlide={4}
              swipeable
              arrows={false}
              autoPlay={false}
              shouldResetAutoplay={false}
              customButtonGroup={<CarouselButtons />}
              itemClass="carousel-item"
              containerClass="carousel-container"
              draggable
            >
              {availableDates.map((element, idx) => {
                return (
                  <CarouselDate
                    key={`date-${idx}`}
                    type={"sunny"}
                    date={element.date}
                    index={idx}
                    handleClick={dateClickHandle}
                    isSelected={
                      !selectedDate
                        ? element.date === availableDates[0].date
                        : element.date === selectedDate?.date
                    }
                  />
                );
              })}
            </Carousel>
          </div>
        </div>
        <div className="fixed-width-section">
          <div
            className="wrapper-reference-date"
            disabled={!selectedArea?.name ? true : false}
          >
            <div className="reference-date" /* disabled={!selectedArea ? true : false} */>
              <div className="heading-on-border">
                <span>Reference date</span>
              </div>
              <p>
                {availableDates[0]?.date === datePlaceholder ||
                availableDates.length === 0
                  ? datePlaceholder
                  : availableDates[0]?.reference_date}
              </p>
            </div>
          </div>
          <div className="search-bar-select relative">
            {/* <label className="select-label">Monitored coastal locations</label> */}
            <Select
              styles={mainSelectStyle}
              value={
                selectedArea?.name ? {value: selectedArea, label: selectedArea.name} : ""
              }
              classNamePrefix="location-select"
              placeholder="Select location"
              onMenuOpen={handleMenuOpen}
              onChange={handleSelectArea}
              options={availableAreas.map((element) => {
                return {value: element, label: element.name};
              })}
            />
          </div>
        </div>
      </div>

      {/* ____ Mobile Header _____ */}
      <div className={`header-modal ${collapsed ? "" : "opened"}`}>
        <button
          onClick={clickHandlerMobile}
          className="wrapper-header-mobile font-roboto"
          {...handlersMenu}
        >
          <div className={`line-mobile ${collapsed ? "" : "opened"}`}></div>
        </button>
        <div className="modal-content">
          <div className="main-header-text">Coastal detection map</div>
          <div className="second-header-text font-extralight">
            Insert and choose parameters for change detection algorithm and check results
            on the map
          </div>
          <div className="ref-date-heading font-semibold">Reference date</div>
          <div
            className="ref-date-placeholder"
            disabled={!selectedArea?.name ? true : false}
          >
            {availableDates[0]?.date === datePlaceholder || availableDates.length === 0
              ? datePlaceholder
              : availableDates[0]?.reference_date}
          </div>
          <div className="search-dropdown-mobile">
            <Select
              styles={mainSelectStyle}
              placeholder="Choose a location..."
              onChange={handleSelectArea}
              options={availableAreas.map((element) => {
                return {value: element, label: element.name};
              })}
            />
          </div>
          <div className="incidents-heading font-semibold">Date of incidents</div>
          <div
            className="dates-mobile"
            /* {...handlersDates} */
            disabled={!selectedArea?.name ? true : false}
          >
            {handleMobileDates()}
          </div>
          <MobileCarouselButtons />
        </div>
      </div>
    </>
  );
};

export default Header;
