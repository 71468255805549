const mainSelectStyle = {
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "rgba(99, 166, 253, 0.1)" : "transparent",
    fontWeight: "normal",
    fontSize: "14px",
    color: "#535353",
    padding: "6px 20px",
    lineHeight: "1.5",
    "&:hover": {
      backgroundColor: "rgba(99, 166, 253, 0.1)",
    },
    cursor: "pointer",
  }),
  menu: (provided) => ({
    ...provided,
    border: "1px solid rgba(0, 0, 0, 0.05)",
    boxShadow: "none",
  }),
  control: (provided) => ({
    ...provided,
    fontWeight: "normal",
    fontSize: "14px",
    borderColor: "#63A6FD",
    "&:hover": {
      borderColor: "rgba(99, 166, 253, 1)",
      boxShadow: "0px 2px 4px rgba(182, 215, 254, 0.4)",
    },
    "&:active": {
      boxShadow: "0px 2px 4px rgba(182, 215, 254, 0.4)",
    },
    boxShadow: "none",
    borderRadius: "15px",
    backgroundColor: "#63A6FD",
  }),
  valueContainer: (provided) => ({
    ...provided,
    paddingLeft: "13px",
    whiteSpace: "nowrap",
    color: "#fff",
  }),
  singleValue: (provided) => ({
    ...provided,
    color: "#fff",
    fontSize: "14px",
  }),
  input: (provided) => ({
    ...provided,
    color: "#fff",
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    backgroundColor: "#fff",
  }),
  svg: (provided) => ({
    ...provided,
    color: "#fff",
  }),
  placeholder: (provided) => ({
    ...provided,
    color: "#fff",
    fontSize: "14px",
  }),
};

export default mainSelectStyle;
