import React, {useState, useEffect} from "react";

import defaultAxios from "axios";
import BeatLoader from "react-spinners/BeatLoader";

import axios from "../Services/axios";
import useSelectedDate from "../Hooks/useSelectedDate";
import SelectAsyncPaginate from "./SelectAsyncPaginate";
import {convertDate} from "../Utils/DateFunctions";

import "../Style/header_slider.css";

// Extension of base URL which is the same for all requests based on change-detection
const BASE_URL_EXTENT = "change-detection/responsible-areas";
const datePlaceholder = "--/--/----";

const HeaderSlider = () => {
  const [currentSelectLeft, setCurrentSelectLeft] = useState({});
  const [currentSelectRight, setCurrentSelectRight] = useState({});
  const [nextPageFetchUrl, setNextPageFetchUrl] = useState("");

  const {setCompareImageReference, selectedArea} = useSelectedDate();

  // Initial API request that checks if there is a reference date in the first 20 dates
  useEffect(() => {
    if (!currentSelectLeft.length && !nextPageFetchUrl) {
      const initialTCIDataReq = async () => {
        const response = await axios.get(`${BASE_URL_EXTENT}/${selectedArea.id}/imagery`);
        response.data.results.forEach((element) => {
          if (element.is_date_reference) {
            // If we have reference date in first 20 dates set it as the left image of the compare view
            return setCurrentSelectLeft(element);
          }
        });
        // If we don't have a reference date in the first 20 dates, then set in the nextPageFetchUrl variable URL for the next request
        // This setState triggers useEffect down below which watch if nextPageFetchUrl variable changed
        setNextPageFetchUrl(response.data.next);
      };

      initialTCIDataReq();
    }
  }, []);

  // Pagination useEffect which triggers until we find reference date
  useEffect(() => {
    if (nextPageFetchUrl && !currentSelectLeft.length) {
      const paginationTCIDataReq = async () => {
        const response = await defaultAxios.get(nextPageFetchUrl);
        response.data.results.forEach((element) => {
          if (element.is_date_reference) {
            return setCurrentSelectLeft(element);
          }
        });
        // Until we find the reference date we will set a nextPageFetchUrl variable with next URL and that will trigger this useEffect again and again
        setNextPageFetchUrl(response.data.next);
      };

      paginationTCIDataReq();
    }
  }, [nextPageFetchUrl]);

  useEffect(() => {
    setCompareImageReference((prev) => {
      return {
        ...prev,
        leftImg: currentSelectLeft,
      };
    });
  }, [currentSelectLeft]);

  useEffect(() => {
    setCompareImageReference((prev) => {
      return {
        ...prev,
        rightImg: currentSelectRight,
      };
    });
  }, [currentSelectRight]);

  return (
    <>
      {!currentSelectLeft.date_time && (
        <div className="loader-component absolute z-[40001]">
          <BeatLoader color="#63A6FD" size={20} />
        </div>
      )}
      <div className="header-slider font-roboto">
        <div className="slider-datepicker label-reference-date">
          <div className="w-full pl-2.5">
            <div className="reference-date" /* disabled={!selectedArea ? true : false} */>
              <div className="heading-on-border">
                <span>Reference date</span>
              </div>
              <p style={{fontSize: "14px"}}>
                {!currentSelectLeft.date_time
                  ? datePlaceholder
                  : convertDate(currentSelectLeft.date_time)}
              </p>
            </div>
          </div>
        </div>
        <h3 className="slider-heading">Compare imagery</h3>
        <div className="slider-datepicker label-selected-date pr-2.5">
          <label htmlFor="SelectedDate" className="block mb-0.5">
            Selected date
          </label>
          <SelectAsyncPaginate onChange={(date) => setCurrentSelectRight(date)} />
        </div>
      </div>
    </>
  );
};

export default HeaderSlider;
