import React from "react";

const InfoIcon = () => {
  return (
    <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 0a12 12 0 1 0 0 24 12 12 0 0 0 0-24ZM1.721 12a10.279 10.279 0 1 1 20.558 0A10.279 10.279 0 0 1 1.72 12ZM13.36 6.564a1.36 1.36 0 1 1-2.718 0 1.36 1.36 0 0 1 2.718 0ZM9.282 9.282H12a.906.906 0 0 1 .906.906v6.342h1.812v1.811H9.282V16.53h1.812v-5.436H9.282V9.282Z"
        fill="#5176A5"
      />
    </svg>
  );
};

export default InfoIcon;
