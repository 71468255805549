import React from "react";

const InfoSmall = () => {
  return (
    <svg width="28" height="28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 0a14 14 0 1 0 0 28 14 14 0 0 0 0-28ZM2.008 14a11.992 11.992 0 1 1 23.984 0 11.992 11.992 0 0 1-23.984 0Zm13.577-6.342a1.585 1.585 0 1 1-3.17 0 1.585 1.585 0 0 1 3.17 0ZM10.83 10.83H14a1.057 1.057 0 0 1 1.057 1.057v7.399h2.114v2.113h-6.342v-2.113h2.114v-6.342H10.83V10.83Z"
        fill="#63A6FD"
      />
    </svg>
  );
};

export default InfoSmall;
