import React, {useEffect} from "react";
import "../Style/infopage.css";
import InfoMap from "../Media/Icons/info_map";
import InfoDashboard from "../Media/Icons/info_dashboard";
import InfoSmall from "../Media/Icons/info_dashboard_small";
import MapSmall from "../Media/Icons/info_map_small";
import EsaCreditsComponent from "./EsaCredits";
import useNotifications from "../Hooks/useNotifications";
import useAuth from "../Hooks/useAuth";
import {useLocation} from "react-router-dom";
import {stepsInfo} from "../Utils/GuideSteps";

const InfoPage = () => {
  const {setAppState} = useNotifications();
  const {auth} = useAuth();

  useEffect(() => {
    let isInfoDashboardFinished = JSON.parse(
      localStorage.getItem("infoDashboardFinished")
    );

    if (auth.access && !isInfoDashboardFinished) {
      setAppState((prev) => {
        return {...prev, run: true, tourActive: true, stepIndex: 3, steps: stepsInfo};
      });

      localStorage.setItem("infoDashboardFinished", true);
    }
    return () => {
      setAppState((prev) => {
        return {...prev, run: false, tourActive: false, stepIndex: 0};
      });
    };
  }, []);

  return (
    <>
      <div className="wrapper-info-page pb-28 lg:h-full lg:pb-10 font-roboto relative select-none">
        <div className="wrapper-heading px-6 lg:px-14">
          <div className="lg:text-xxl font-light text-white text-heading-first">
            We
            <span className="font-semibold relative">
              Sea
              <span className="text-gray-300 lg:text-xl font-thin text-heading-second">
                Coastal changes
              </span>
            </span>
          </div>
        </div>
        <div className="wrapper-cards px-6 lg:px-12 lg:mt-6 font-roboto">
          <div className="transition-container">
            <div className="content">
              <div className="card">
                <div className="mobile">
                  <button className="bg-white btn-style">
                    <div className="info-big">
                      <InfoDashboard />
                    </div>
                    <div className="info-small">
                      <InfoSmall />
                    </div>
                  </button>
                  <div className="lg:text-l text-white flex justify-center lg:mt-8 font-semibold text-mobile">
                    Project info
                  </div>
                </div>
                <div className="display-mobile">
                  <b>WeSea</b> aims to be an an innovative software solution for automated
                  monitoring and classification of coastal changes (mainly focusing on
                  illegal construction), based on space technology products. We want to
                  reduce the level of anthropogenic devastation of maritime assets, which
                  would result in a more efficient coastal environment management model.
                  <br></br>This proof of concept app is being developed under First Call
                  For Outline Proposals Under The Cooperation Agreement Space Cooperation
                  For Peaceful Purpose With Croatia.
                </div>
              </div>
              <div className="p-10 absolute top-0 card-back text-white text-base">
                <b>WeSea</b> aims to be an an innovative software solution for automated
                monitoring and classification of coastal changes (mainly focusing on
                illegal construction), based on space technology products. We want to
                reduce the level of anthropogenic devastation of maritime assets, which
                would result in a more efficient coastal environment management model.
                <br></br>This proof of concept app is being developed under First Call For
                Outline Proposals Under The Cooperation Agreement Space Cooperation For
                Peaceful Purpose With Croatia.
              </div>
            </div>
          </div>
          <div className="transition-container">
            <div className="content">
              <div className="card">
                <div className="mobile">
                  <button className="bg-white btn-style">
                    <div className="info-big">
                      <InfoMap />
                    </div>
                    <div className="info-small">
                      <MapSmall />
                    </div>
                  </button>
                  <div className="lg:text-l text-white flex justify-center lg:mt-8 font-semibold text-mobile">
                    Map
                  </div>
                </div>
                <div className="display-mobile">
                  Check out the interactive map and visualize all major coastal changes
                  from 2019 till now, throughout entire Croatia. We are continuously
                  updating the app &amp; algorithm as well as adding new features.
                  That&apos;s why your opinion is really important to us! If you have any
                  questions, ideas, want to try a demo or propose a new location that will
                  be monitored, let us know at wesea@listlabs.net
                </div>
              </div>
              <div className="p-10 absolute top-0 card-back text-white text-base">
                Check out the interactive map and visualize all major coastal changes from
                2019 till now, throughout entire Croatia. We are continuously updating the
                app &amp; algorithm as well as adding new features. That&apos;s why your
                opinion is really important to us! If you have any questions, ideas, want
                to try a demo or propose a new location that will be monitored, let us
                know at{" "}
                <a href="mailto:wesea@listlabs.net" className="hover:underline">
                  <b>wesea@listlabs.net</b>
                </a>
              </div>
            </div>
          </div>
        </div>
        <EsaCreditsComponent />
      </div>
    </>
  );
};

export default InfoPage;
