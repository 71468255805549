import React from "react";

const CloseIcon = () => {
  return (
    <svg width="13" height="13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M.257.257a.875.875 0 0 1 1.24 0l4.63 4.632 4.63-4.632a.875.875 0 1 1 1.24 1.24l-4.633 4.63 4.632 4.63a.876.876 0 1 1-1.239 1.24l-4.63-4.633-4.63 4.632a.876.876 0 0 1-1.24-1.239l4.632-4.63-4.632-4.63a.875.875 0 0 1 0-1.24Z"
        fill="#63A6FD"
      />
    </svg>
  );
};

export default CloseIcon;
