import React, {createContext, useState} from "react";

const IncidentsLayerContext = createContext({});

export const IncidentsLayerProvider = ({children}) => {
  const [layerData, setLayerData] = useState({});

  return (
    <IncidentsLayerContext.Provider value={{layerData, setLayerData}}>
      {children}
    </IncidentsLayerContext.Provider>
  );
};

export default IncidentsLayerContext;
