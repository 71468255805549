import React from "react";
import useNotifications from "../Hooks/useNotifications";
import {Alert, Snackbar} from "@mui/material";
import AlertTitle from "@mui/material/AlertTitle";

const Notification = () => {
  const {showNotification, errMsg, messageStatus} = useNotifications();

  return (
    <>
      {showNotification && errMsg && (
        <>
          {showNotification && errMsg && (
            <Snackbar
              open={showNotification}
              anchorOrigin={{horizontal: "right", vertical: "bottom"}}
              sx={{
                zIndex: 5000,
              }} /* STYLE FOR NOTIFICATIONS GOES HERE NOT IN CSS!!! https://mui.com/material-ui/customization/how-to-customize/ */
            >
              <Alert severity={messageStatus}>
                <AlertTitle>
                  {messageStatus.trim().replace(/^\w/, (c) => c.toUpperCase())}
                </AlertTitle>
                {errMsg}
              </Alert>
            </Snackbar>
          )}
        </>
      )}
    </>
  );
};

export default Notification;

/* 
"error"
"warning"
"info"
"success" 

<Alert severity={messageStatus}>{errMsg}</Alert>
*/

/* <>
      {showNotification && errMsg && (
        <Snackbar
          open={showNotification}
          anchorOrigin={{horizontal: "right", vertical: "top"}}
        >
          <Alert severity={messageStatus}>
            <AlertTitle>{messageStatus}</AlertTitle>
            {errMsg}
          </Alert>
        </Snackbar>
      )}
    </>*/
