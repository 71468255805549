import React, {useEffect} from "react";
import {Routes, Route} from "react-router-dom";
import MapLeaflet from "./Components/Mapleaflet";
import "./App.css";
import InfoPage from "./Components/InfoPage";
import Dashboard from "./Components/Dashboard";
import LoginPage from "./Components/LoginPage";
import Layout from "./Components/Layout";
import RequireAuth from "./Components/RequireAuth";
import PersistLogin from "./Components/PersistLogin";
import {IncidentsLayerProvider} from "./Services/IncidentsLayerProvider";
import Notification from "./Components/Notification";
import useNotifications from "./Hooks/useNotifications";
import useLocalStorage from "./Hooks/useLocalStorage";
import GuideTooltip from "./Components/GuideTooltip";
import Joyride, {STATUS, EVENTS, ACTIONS} from "react-joyride";
import "./Style/step-guide.css";
import ReactGA from "react-ga";
import Logout from "./Components/Logout";
import {stepsInfo} from "./Utils/GuideSteps";

export default function App() {
  const TRACKING_ID = "UA-167102395-7";
  ReactGA.initialize(TRACKING_ID);

  const {appState, setAppState} = useNotifications();

  /*   const [guideEnable, setGuideEnable] = useState(
    window?.innerWidth > 1024 && window?.innerHeight > 600 ? true : false
  );
 */

  const setStorageItem = useLocalStorage();

  useEffect(() => {
    if (window.innerWidth < 1024) {
      setAppState((prev) => {
        return {
          ...prev,
          enabled: false,
        };
      });
    } else if (!appState?.enabled) {
      setAppState((prev) => {
        return {
          ...prev,
          enabled: true,
        };
      });
    }

    const resizeHandler = () => {
      if (window.innerWidth < 1024) {
        setAppState((prev) => {
          return {
            ...prev,
            run: false,
            tourActive: false,
            enabled: false,
          };
        });
      } else {
        if (appState.enabled === false) {
          setAppState((prev) => {
            return {
              ...prev,
              run: false,
              tourActive: false,
              enabled: true,
            };
          });
        }
      }
    };

    window.addEventListener("resize", resizeHandler);

    if (!JSON.parse(localStorage.getItem("infoGuideFinished")))
      setAppState((prev) => {
        return {...prev, run: true && prev.enabled, tourActive: true, steps: stepsInfo};
      });
  }, []);

  const handleJoyrideCallback = (data) => {
    let {action, index, status, type, step} = data;
    const options = [STATUS.FINISHED, STATUS.SKIPPED];

    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      // Need to set our running state to false, so we can restart if we click start again.

      setStorageItem(`${step.route}GuideFinished`, true);

      setAppState((prev) => {
        return {
          ...prev,
          tourActive: false,
          run: false,
        };
      });
    } else if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      setAppState((prev) => {
        return {...prev, stepIndex: index + (action === ACTIONS.PREV ? -1 : 1)};
      });

      // Update state to advance the tour
    }

    if (options.includes(status)) {
      setAppState((prev) => {
        return {
          ...prev,
          tourActive: false,
          run: false,
        };
      });
    }
  };

  return (
    <>
      <Notification />
      <Joyride
        continuous
        disableOverlayClose={true}
        disableCloseOnEsc={true}
        run={appState.run}
        callback={handleJoyrideCallback}
        showSkipButton
        steps={appState.steps}
        tooltipComponent={GuideTooltip}
        stepIndex={appState.stepIndex}
        locale={{last: "Finish"}}
        styles={{
          options: {
            zIndex: 6000,
          },
        }}
      />
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route path="/" element={<InfoPage />} />
          <Route
            path="map"
            element={
              <IncidentsLayerProvider>
                <MapLeaflet />
              </IncidentsLayerProvider>
            }
          />
          <Route path="login" element={<LoginPage />} />
          <Route path="logout" element={<Logout />} />
          {/* PROTECTED ROUTES */}
          <Route element={<PersistLogin />}>
            <Route element={<RequireAuth />}>
              <Route path="dashboard" element={<Dashboard />} />
            </Route>
          </Route>
        </Route>
      </Routes>
    </>
  );
}
