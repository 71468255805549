import {useEffect, useState, useCallback} from "react";
import ReactDOMServer from "react-dom/server";

import useSelectedDate from "../Hooks/useSelectedDate";
import IncidentPopup from "./IncidentPopup";
import useIncidentsLayer from "../Hooks/useIncidentsLayer";
import Marker from "../Media/Icons/marker";

import L from "leaflet";
import axios from "../Services/axios";

const BASE_URL_EXTENT = "change-detection/responsible-areas/";

const ChangeDetectionVectors = ({mapRef}) => {
  const {selectedArea, selectedDate} = useSelectedDate();
  const [vectorData, setVectorData] = useState();
  const [clickedPopup, setClickedPopup] = useState();

  const MarkerIcon = new L.divIcon({
    className: "detection-marker",
    iconAnchor: [11, 32],
    labelAnchor: [-6, 0],
    popupAnchor: [0, -36],
    html: ReactDOMServer.renderToString(<Marker markercolor={"red"} />),
  });

  const popupOpen = useCallback(
    (e) => {
      let anchorLocation = mapRef.target.project(e.target._popup._latlng);
      anchorLocation.y -= e.target._popup._container.clientHeight / 2;
      mapRef.target.panTo(mapRef.target.unproject(anchorLocation), {animate: true});

      let clickedPopupCoordinates = [];
      clickedPopupCoordinates.push(e.target._latlng.lat, e.target._latlng.lng);

      let vectorPopupData = vectorData.filter((vector) => {
        if (
          vector.point.geometry.coordinates.every((i) =>
            clickedPopupCoordinates.includes(i)
          )
        ) {
          return vector;
        }
      });
      setClickedPopup(vectorPopupData[0]);
    },
    [mapRef, vectorData]
  );

  useEffect(() => {
    // When area is selected, automatically selectedDate variable gets data for first date
    // Request for vector incidents based on selected area and selected date
    // When we get vectorData second useEffect triggers
    if (selectedDate?.id) {
      const fetchIncidentsMainMap = async () => {
        const response = await axios.get(
          `${BASE_URL_EXTENT}${selectedArea.id}/cd-vectors/`,
          {
            params: {
              change_detection_id: selectedDate.id,
            },
          }
        );
        setVectorData(response.data);
      };

      fetchIncidentsMainMap();
    }

    return () => {
      // Removing markers on date change
      mapRef?.target?.eachLayer(function (layer) {
        if (layer instanceof L.Marker) {
          mapRef?.target?.removeLayer(layer);
        }
      });
    };
  }, [mapRef, selectedDate, selectedArea.id, setVectorData]);

  useEffect(() => {
    // Based on selected date markers are creating and adding to map
    if (vectorData?.length) {
      vectorData.map((layer) => {
        let coordinates = layer.point.geometry.coordinates;
        let marker = L.marker(coordinates.reverse(), {icon: MarkerIcon});
        marker.addTo(mapRef.target);

        return null;
      });
    }
  }, [mapRef, vectorData, selectedArea]);

  useEffect(() => {
    if (mapRef?.target) {
      mapRef.target.eachLayer(function (layer) {
        if (layer instanceof L.Marker) {
          layer
            .addEventListener("popupopen", popupOpen)
            .bindPopup(
              ReactDOMServer.renderToString(
                <IncidentPopup
                  feature={selectedDate}
                  area={selectedArea}
                  vector={clickedPopup}
                />
              )
            );
        }
      });
    }

    return () => {
      mapRef?.target?.eachLayer(function (layer) {
        if (layer instanceof L.Marker) {
          layer.removeEventListener("popupopen", popupOpen);
        }
      });
    };
  }, [mapRef, clickedPopup, selectedArea, selectedDate, popupOpen]);

  useEffect(() => {
    // Removing markers on area change when you come from for example Login page
    mapRef?.target?.eachLayer(function (layer) {
      if (layer instanceof L.Marker) {
        mapRef.target.removeLayer(layer);
      }
    });
  }, [mapRef, selectedArea]);

  return;
};

export default ChangeDetectionVectors;
