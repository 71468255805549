import React, {useRef, useEffect} from "react";
import {NavLink} from "react-router-dom";
import useNotifications from "../Hooks/useNotifications";
import LoginButton from "./LoginButton";
import DashboardIcon from "../Media/Icons/Dashboard";
import InfoIcon from "../Media/Icons/Info";
import LogoIcon from "../Media/Icons/Logo";
import MapIcon from "../Media/Icons/Map";
import HelpIcon from "../Media/Icons/Help";
import "../Style/sidebar.css";
import {useLocation} from "react-router-dom";
import {stepsInfo} from "../Utils/GuideSteps";
import useAuth from "../Hooks/useAuth";

const Sidebar = ({isOpen, onClickOutside}) => {
  const ref = useRef(null);
  const {appState, setAppState} = useNotifications();
  const location = useLocation();
  const {auth} = useAuth();

  useEffect(() => {
    const handleClickOutside = (event) => {
      //disable for close menu button
      if (event.target.classList.contains("menu-button")) {
        return;
      } else if (ref.current && !ref.current.contains(event.target)) {
        onClickOutside && onClickOutside();
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    document.addEventListener("touchstart", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
      document.removeEventListener("touchstart", handleClickOutside, true);
    };
  }, [onClickOutside]);

  const helpClick = () => {
    if (location.pathname === "/") {
      localStorage.removeItem("infoGuideFinished");
      setAppState((prev) => {
        return {...prev, run: true, tourActive: true, stepIndex: 0, steps: stepsInfo};
      });
    } else {
      let route = appState?.steps[0]?.route;
      localStorage.removeItem(`${route}GuideFinished`);
      setAppState((prev) => {
        return {...prev, run: true && prev.enabled, tourActive: true, stepIndex: 0};
      });
    }
  };

  return (
    <div
      ref={ref}
      className={`${
        isOpen ? "show" : "hide"
      } font-roboto wrapper-sidebar absolute left-0 top-0 bottom-0 w-20 bg-blue-400`}
    >
      <div className="my-6 mt-6 mx-5 pointer-events-none sm:hidden h-6"></div>
      <div className="sidebar-content">
        <div className="sm:mt-5 sm:mx-5 logo text-center">
          <LogoIcon />
        </div>
        <div className="px-7 mt-12 mb-0 icons-position border-b nav-links">
          <NavLink to={"/"}>
            <button title="Info page" className="mb-8 info-icon">
              <InfoIcon />
            </button>
          </NavLink>
          <NavLink to={"/map"}>
            <button title="Map" className="mb-8 map-icon">
              <MapIcon />
            </button>
          </NavLink>
          {auth.access && (
            <NavLink to={"/dashboard"}>
              <button title="Dashboard" className="mb-8 dashboard-icon">
                <DashboardIcon className="pb-6" />
              </button>
            </NavLink>
          )}
        </div>
        <div title="Login">
          <LoginButton isSidebarOpen={isOpen} />
        </div>
      </div>
      {appState?.enabled && (
        <div className="help-btn">
          {" "}
          <button title="Help" onClick={helpClick} className={"restart-guide test"}>
            <HelpIcon />
          </button>
        </div>
      )}
    </div>
  );
};

export default Sidebar;
