import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {AuthProvider} from "./Services/AuthProvider";
import LoaderComponent from "./Components/LoaderComponent";
import {NotificationsProvider} from "./Services/NotificationsProvider";
import {SelectedDateProvider} from "./Services/SelectedDateProvider";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <NotificationsProvider>
      <AuthProvider>
        <SelectedDateProvider>
          <LoaderComponent />
          <Routes>
            <Route path="/*" element={<App />} />
          </Routes>
        </SelectedDateProvider>
      </AuthProvider>
    </NotificationsProvider>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
