import React from "react";

const InfoMap = () => {
  return (
    <svg width="110" height="110" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M55 0a55 55 0 1 0 0 110A55 55 0 0 0 55 0ZM7.89 55a47.11 47.11 0 1 1 94.22 0 47.11 47.11 0 0 1-94.22 0Zm53.338-24.913a6.228 6.228 0 1 1-12.456 0 6.228 6.228 0 0 1 12.456 0ZM42.543 42.543H55a4.152 4.152 0 0 1 4.152 4.153V75.76h8.305v8.304H42.543v-8.304h8.305V50.848h-8.305v-8.305Z"
        fill="#63A6FD"
      />
    </svg>
  );
};

export default InfoMap;
