import React, {createContext, useState} from "react";

const NotificationsContext = createContext({});

export const NotificationsProvider = ({children}) => {
  const [showNotification, setShowNotification] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [messageStatus, setMessageStatus] = useState("");
  const [appState, setAppState] = useState({
    run: false,
    stepIndex: 0,
    steps: [],
    tourActive: false,
    enabled: window.innerWidth > 1280 && window.innerHeight > 600 ? true : false,
  });

  const setMessage = (message, status) => {
    setErrMsg(message);
    setMessageStatus(status);
    setShowNotification(true);

    setTimeout(() => {
      setShowNotification(false);
    }, 5000);
  };

  return (
    <NotificationsContext.Provider
      value={{showNotification, errMsg, messageStatus, setMessage, appState, setAppState}}
    >
      {children}
    </NotificationsContext.Provider>
  );
};

export default NotificationsContext;
