import React from "react";

const SliderIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 4H5C3.9 4 3 4.9 3 6V18C3 19.1 3.9 20 5 20H10V24H12V13V0H10V4ZM19 4H14V6H19V18H14V20H19C20.1 20 21 19.1 21 18V6C21 4.9 20.1 4 19 4Z"
        fill="#333333"
      />
    </svg>
  );
};

export default SliderIcon;
