export const colorList = [
  "#ffffb2",
  "#fff19a",
  "#ffe281",
  "#ffd468",
  "#fec357",
  "#feb14e",
  "#fe9f45",
  "#fd8d3c",
  "#fa7634",
  "#f65e2c",
  "#f24624",
  "#e93220",
  "#da2122",
  "#cc1024",
  "#bd0026",
];
