import React from "react";

import useSelectedDate from "../Hooks/useSelectedDate";
import ForwardMobileIcon from "../Media/Icons/forward_mobile_icon";
import BackMobileIcon from "../Media/Icons/back_mobile_icon";

const MobileCarouselButtons = () => {
  const {
    selectedArea,
    mobileDatesRange,
    setMobileDatesRange,
    fetchNewAvailableDates,
    setFetchNewAvailableDates,
  } = useSelectedDate();

  const handleMobileDatesChange = (forward) => {
    let start = mobileDatesRange.start;
    let end = mobileDatesRange.end;
    let len = mobileDatesRange.len;

    // change of pagination triggers useEffect in SelectedDateProvider
    if (start === 0 && !forward) {
      setFetchNewAvailableDates((prev) => {
        return {
          ...prev,
          pagination: prev.pagination + 1,
          fetchDirection: "backward",
        };
      });
    }
    if (end === len && forward) {
      setFetchNewAvailableDates((prev) => {
        return {
          ...prev,
          pagination: prev.pagination - 1,
          fetchDirection: "forward",
        };
      });
    }

    // Hndling which dates will show inside dates layout
    if (forward) {
      // if you want to know what's happening, debug it
      if (start + end < 4) {
        setMobileDatesRange((prev) => {
          return {...prev, start: end, end: end + 4};
        });
      } else if (end + 4 <= len) {
        setMobileDatesRange((prev) => {
          return {...prev, start: start + 4, end: start + 8};
        });
      } else if (end < len) {
        setMobileDatesRange((prev) => {
          return {...prev, start: start + 4, end: len};
        });
      }
      //backwards
    } else {
      if (start >= 4) {
        setMobileDatesRange((prev) => {
          return {...prev, start: start - 4, end: start};
        });
      }
      if (start < 4) {
        setMobileDatesRange((prev) => {
          return {...prev, start: 0, end: start};
        });
      }
    }
  };

  return (
    <div className="arrows-mobile" disabled={!selectedArea?.name ? true : false}>
      <button
        className="arrow-child-mobile bg-white hover:bg-blue-100"
        onClick={() => handleMobileDatesChange(false)}
        disabled={mobileDatesRange.start === 0 && !fetchNewAvailableDates.backward}
      >
        <BackMobileIcon />
      </button>
      <div className="gap-mobile"></div>
      <button
        className="arrow-child-mobile bg-white hover:bg-blue-100"
        onClick={() => handleMobileDatesChange(true)}
        disabled={
          mobileDatesRange.end === mobileDatesRange.len && !fetchNewAvailableDates.forward
        }
      >
        <ForwardMobileIcon />
      </button>
    </div>
  );
};

export default MobileCarouselButtons;
