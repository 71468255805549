import useNotifications from "./useNotifications";

const useLocalStorage = () => {
  const {setMessage} = useNotifications();
  const setStorageItem = (key, value) => {
    try {
      localStorage.setItem(key, value);
      return true;
    } catch (err) {
      setMessage(
        "Local storage full! Some parts of app might not work properly!",
        "error"
      );
    }
  };

  return setStorageItem;
};

export default useLocalStorage;
