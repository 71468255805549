import React, {useEffect, useState, useCallback} from "react";
import "../Style/dashboard.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {Chart} from "./Chart";
import Select from "react-select";
import regularSelectStyle from "../Utils/regularSelectStyle";
import {DetectionsTableData} from "./DetectionsTableData";
import useSelectedDate from "../Hooks/useSelectedDate";
import useNotifications from "../Hooks/useNotifications";
import {stepsDashboard} from "../Utils/GuideSteps";

const Dashboard = () => {
  const {
    selectedArea,
    setSelectedArea,
    availableAreas,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
  } = useSelectedDate();

  const {appState, setAppState} = useNotifications();

  const [tableHasData, setTableHasData] = useState(false);

  const hasDataHandler = useCallback(
    (bool) => {
      bool ? setTableHasData(true) : setTableHasData(false);
    },
    [tableHasData]
  );

  useEffect(() => {
    let dashboardGuideState = JSON.parse(localStorage.getItem("dashboardGuideFinished"));
    if (!dashboardGuideState) {
      setAppState((prev) => {
        return {
          ...prev,
          run: true && prev.enabled,
          tourActive: true,
          steps: stepsDashboard,
          stepIndex: 0,
        };
      });
    } else {
      setAppState((prev) => {
        return {...prev, steps: stepsDashboard};
      });
    }

    return () => {
      setAppState((prev) => {
        return {...prev, run: false, tourActive: false, stepIndex: 0};
      });
    };
  }, []);

  useEffect(() => {
    let guideFinished = localStorage.getItem("dashboardGuideFinished");
    if (!(guideFinished === "true") && appState?.stepIndex === 1) {
      setTimeout(() => {
        setAppState((prev) => {
          return {...prev, stepIndex: 2, run: true && prev.enabled};
        });
      }, 10);
    }

    if (!(guideFinished === "true") && appState?.stepIndex === 2) {
      setTimeout(() => {
        setAppState((prev) => {
          return {...prev, stepIndex: 3, run: true && prev.enabled};
        });
      }, 500);
    }
  }, [tableHasData, selectedArea]);

  const handleSelectDateRange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const handleSelectArea = (selected) => {
    setSelectedArea(selected.value);
  };

  // Guide handler
  const handleMenuOpen = () => {
    if (appState.stepIndex === 2 && appState.run) {
      setAppState((prev) => {
        return {
          ...prev,
          run: false,
        };
      });
    } else if (appState.stepIndex === 1 && appState.run) {
      if (selectedArea?.id) {
        setAppState((prev) => {
          return {
            ...prev,
            stepIndex: 2,
          };
        });
      } else {
        setAppState((prev) => {
          return {
            ...prev,
            run: false,
          };
        });
      }
    }
  };

  return (
    <div className="p-5 pt-6 bg-white lg:h-full relative font-semibold font-roboto">
      <h2 className="text-center sm:text-left text-l text-blue-300 mb-4 pb-2 leading-none pl-12">
        Dashboard
      </h2>
      <div className="mb-5 md:mb-0 md:absolute md:top-6 md:z-50 font-roboto relative location-filter">
        <label htmlFor="location" className="block mb-0.5">
          Filter by location
        </label>
        <Select
          styles={regularSelectStyle}
          placeholder="Choose a location..."
          value={
            selectedArea?.name ? {value: selectedArea, label: selectedArea.name} : ""
          }
          onChange={handleSelectArea}
          onMenuOpen={handleMenuOpen}
          options={availableAreas.map((element) => {
            return {value: element, label: element.name};
          })}
        />
      </div>
      <div className="mb-5 md:mb-0 md:absolute md:top-6 md:right-5 md:z-50 font-roboto relative">
        <label htmlFor="date-picker" className="block mb-0.5">
          Time span
        </label>
        <DatePicker
          placeholderText="Choose time span..."
          id="date-picker"
          onChange={handleSelectDateRange}
          startDate={startDate}
          endDate={endDate}
          dateFormat="MM/yyyy"
          selectsRange
          showMonthYearPicker
          onCalendarOpen={handleMenuOpen}
        />
      </div>
      <div
        className={`dashboard-wrapper ${
          selectedArea?.id && startDate && endDate ? "" : "disabled"
        } lg:flex lg:justify-center lg:items-stretch relative z-10`}
      >
        <div className="mb-4 lg:mb-0 shadow-lg lg:mr-2.5 rounded-2xl overflow-y-scroll table-container">
          <DetectionsTableData
            location={selectedArea?.id}
            start={startDate}
            end={endDate}
            hasdata={hasDataHandler}
          />
        </div>
        <div className="lg:ml-2.5 graph-container">
          <div className="p-7 pt-5 shadow-lg mb-4 rounded-2xl graph-box overflow-y-scroll lg:flex lg:flex-col">
            <h3 className="text-lg font-semibold mb-2 text-gray-200">
              Number of detections
            </h3>
            <Chart
              location={selectedArea?.id}
              start={startDate}
              end={endDate}
              chartType="graph-numdetections"
              label="Number of detections"
              chartStepSize={1}
            />
          </div>
          <div className="p-7 pt-5 shadow-lg mb-4 rounded-2xl graph-box overflow-y-scroll lg:flex lg:flex-col">
            <h3 className="text-lg font-semibold mb-2 text-gray-200">
              Area changed (m<sup>2</sup>)
            </h3>
            <Chart
              location={selectedArea?.id}
              start={startDate}
              end={endDate}
              chartType="graph-areasum"
              label="Area changed"
              chartStepSize={10}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
