import React from "react";

const BackMobileIcon = () => {
  return (
    <svg width="40" height="40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.5 20a17.5 17.5 0 1 0 35 0 17.5 17.5 0 0 0-35 0ZM40 20a20 20 0 1 1-40 0 20 20 0 0 1 40 0Zm-11.25-1.25a1.25 1.25 0 0 1 0 2.5H14.268l5.367 5.365a1.252 1.252 0 0 1-1.77 1.77l-7.5-7.5a1.25 1.25 0 0 1 0-1.77l7.5-7.5a1.252 1.252 0 0 1 1.77 1.77l-5.368 5.365H28.75Z"
        fill="#63A6FD"
      />
    </svg>
  );
};

export default BackMobileIcon;
