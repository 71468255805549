import React from "react";
import "../Style/step-guide.css";
import Button from "./Button";
import CloseButton from "../Media/Icons/close_btn";

const GuideTooltip = ({
  backProps,
  continuous,
  index,
  isLastStep,
  primaryProps,
  skipProps,
  step,
  tooltipProps,
}) => {
  return (
    <div className="joyride-wrapper" {...tooltipProps}>
      <div className="joyride-icon">
        {step.background ? (
          step.background
        ) : (
          <div className="info-blue-bg">
            <div className="joyride-skip">
              {!isLastStep && (
                <button {...skipProps} buttonclass={"joyride-button-skip"}>
                  <CloseButton />
                </button>
              )}
            </div>
            {step.icon}
          </div>
        )}
      </div>
      <div className={step.introElement ? "joyride-body" : "joyride-body-rest"}>
        <div className="title-style font-medium">
          {step.title && <h3>{step.title}</h3>}
        </div>
        <div className="joyride-body-content">{step.content}</div>
        <div className="joyride-navigation hover:bg-blue-200">
          {index > 0 && (
            <Button {...backProps} buttonclass={"joyride-button-back"}>
              Back
            </Button>
          )}
          <Button
            {...primaryProps}
            buttonclass={`joyride-button-${continuous ? "next" : "close"} ${
              step.introElement ? "intro-element-btn" : "intro-rest-btn"
            }`}
          >
            {continuous ? "Next" : "Close"}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default GuideTooltip;
