import React from "react";

import defaultAxios from "axios";
import axios from "../Services/axios";
import {AsyncPaginate} from "react-select-async-paginate";

import useSelectedDate from "../Hooks/useSelectedDate";
import regularSelectStyle from "../Utils/regularSelectStyle";
import {convertDate} from "../Utils/DateFunctions";

// Extension of base URL which is the same for all requests based on change-detection
const BASE_URL_EXTENT = "change-detection/responsible-areas";

const SelectAsyncPaginate = ({onChange}) => {
  const {selectedArea} = useSelectedDate();

  // Loading TCI (True Color Images) data from backend
  const loadOptions = async (search, loadedOptions, {page}) => {
    // Initial fetch when select opens
    if (page === 1) {
      const response = await axios.get(
        `${BASE_URL_EXTENT}/${selectedArea.id}/imagery-adjusted`
      );
      // Preparing data for AsyncPaginate component, this object is predefined for async select which we use
      // Inside additional.page property we save URL for next request which will happend when you come to the bottom of the select options
      return {
        options: response.data.results,
        hasMore: response.data.next ? true : false,
        additional: {
          page: response.data.next,
        },
      };
    } else {
      // Pagination requests after initial fetch, we get new data when we get to the bottom of the select options
      // We make these requests until response.data.next property is null
      const response = await defaultAxios.get(page);
      return {
        options: response.data.results,
        hasMore: response.data.next ? true : false,
        additional: {
          page: response.data.next,
        },
      };
    }
  };

  const handleDateSelection = (date) => {
    // Props callback function - date goes to HeaderSlider component
    onChange(date);
  };

  return (
    <AsyncPaginate
      styles={regularSelectStyle}
      placeholder={"--/--/----"}
      loadOptions={loadOptions}
      getOptionValue={(option) => option}
      getOptionLabel={(option) => convertDate(option.date_time)}
      onChange={handleDateSelection}
      additional={{
        page: 1,
      }}
      menuPlacement="auto"
    />
  );
};

export default SelectAsyncPaginate;
