import React, {useState, useEffect} from "react";
import axios from "../Services/axios";

import useSelectedDate from "../Hooks/useSelectedDate";
import ArticleIcon from "../Media/Icons/ArticleIcon";

import "../Style/media_article.css";

const MediaArticles = ({mapRef}) => {
  const {selectedArea} = useSelectedDate();
  const [isMediaShown, setIsMediaShown] = useState(false);
  const [mediaArticles, setMediaArticles] = useState([]);

  const onMediaClick = () => {
    if (selectedArea.id) {
      setIsMediaShown((prev) => !prev);
    }
  };

  const goToArticleCoordinates = (articleId) => {
    let filteredArticle = mediaArticles.find((article) => {
      if (article.id === articleId) {
        return article;
      }
      return null;
    });
    let coordinates = filteredArticle.location.coordinates;
    mapRef.target.flyTo(coordinates, 18);
  };

  useEffect(() => {
    if (isMediaShown && selectedArea.id) {
      const getMediaArticles = async () => {
        const response = await axios.get(
          `change-detection/responsible-areas/${selectedArea.id}/media-articles/`
        );

        response.data.results.map((article) => {
          let coordinates = article.location.coordinates.reverse();
          setMediaArticles((prev) => {
            return {
              ...prev,
              coordinates: coordinates,
            };
          });
          return null;
        });

        setMediaArticles(response.data.results);
      };

      getMediaArticles();
    }

    return () => {
      setMediaArticles([]);
    };
  }, [isMediaShown, selectedArea.id]);

  return (
    <>
      <button
        className={`${
          !selectedArea?.id
            ? ""
            : `${isMediaShown ? "bg-blue-100" : "hover:bg-blue-100"} `
        } media-button md:flex md:justify-center md:items-center cursor-pointer group rounded-full z-[400] w-12 h-12 bg-white fixed left-[90px] top-[95px] disabled:cursor-default shadow-[1px_1px_5px_1px_rgba(155,163,175,0.4)]`}
        onClick={onMediaClick}
        disabled={!selectedArea.id}
      >
        <ArticleIcon selectedArea={selectedArea} isMediaActive={isMediaShown} />
      </button>
      {isMediaShown && (
        <div className="fixed left-[90px] top-[155px] z-[500] w-[400px] bg-[#F7FAFF] rounded-xl px-5 py-4 h-2/4 shadow-[1px_1px_8px_2px_rgba(155,163,175,0.4)]">
          <div className="mb-2.5 text-[24px]">Media Articles</div>
          {mediaArticles.map((article) => {
            return (
              <div
                key={article.id}
                className="flex justify-between items-center space-x-3 mt-1.5"
              >
                <a
                  href={article.url}
                  title={article.title}
                  target="_blank"
                  rel="noreferrer"
                  className="text-md"
                >
                  <span className="hover-underline-animation">{article.title}</span>
                </a>
                <div>
                  <button
                    className="border-[1px] rounded-md w-[90px] text-gray-400 border-blue-100 p-2 text-xs hover:bg-blue-100 hover:text-white hover:border-blue-100"
                    onClick={() => goToArticleCoordinates(article.id)}
                  >
                    Find On Map
                  </button>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};

export default MediaArticles;
