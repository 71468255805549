import React, {useState, useEffect} from "react";
import {useMapEvents} from "react-leaflet";
import {Util} from "leaflet";
import lodashGroupBy from "lodash.groupby";
import {LayersControlProvider} from "../Hooks/layerControlContext";

import createControlledLayer from "../Hooks/controlledLayer";

import MapLayerIcon from "../Components/MapLayerIcon";
import BaseMapIcon from "./BaseMapIcon";
import "../Style/layer_control.css";

import useSelectedDate from "../Hooks/useSelectedDate";

// Classes used by Leaflet to position controls
const POSITION_CLASSES = {
  bottomleft: "leaflet-bottom leaflet-left",
  bottomright: "leaflet-bottom leaflet-right",
  topleft: "leaflet-top leaflet-left",
  topright: "leaflet-top leaflet-right",
};

function LayerControl({position, children}) {
  const [collapsed, setCollapsed] = useState(true);
  const [layers, setLayers] = useState([]);
  const [activeBase, setActiveBase] = useState("OSM");
  const {selectedDate} = useSelectedDate();
  const positionClass =
    (position && POSITION_CLASSES[position]) || POSITION_CLASSES.topright;

  const map = useMapEvents({
    layerremove: () => {
      // console.log("layer removed");
    },
    layeradd: () => {
      // console.log("layer add");
    },
    // eslint-disable-next-line no-unused-vars
    overlayadd: (layer, extra) => {
      // console.log(layer, extra);
    },
  });

  const onLayerClickRadio = (layerObj) => {
    // eslint-disable-next-line no-empty
    if (map?.hasLayer(layerObj.layer)) {
    } else {
      map.addLayer(layerObj.layer);
      let oldActiveBase = layers.filter((element) => {
        return element.group === "Base Layers" && element.checked;
      });
      map.removeLayer(oldActiveBase[0]?.layer);
      map.fire("baselayerchange");

      setLayers(
        layers.map((layer) => {
          if (layer.id !== layerObj.id && layer.group === "Base Layers") {
            return {
              ...layer,
              checked: false,
            };
          } else if (layer.id === layerObj.id) {
            setActiveBase(layer.name);
            return {
              ...layer,
              checked: true,
            };
          } else {
            return {
              ...layer,
            };
          }
        })
      );
    }
  };

  const onLayerClickCheckbox = (layerObj) => {
    let imageryLayer = null;
    layerObj.layer.eachLayer((groupLayer) => {
      imageryLayer = groupLayer;
    });
    if (map?.hasLayer(layerObj.layer) && imageryLayer?.options?.opacity > 0) {
      // hiding the layer by giving it an opacity of 0
      imageryLayer.setOpacity(0);
      setLayers(
        layers.map((layer) => {
          if (layer.id === layerObj.id)
            return {
              ...layer,
              checked: false,
            };
          return layer;
        })
      );
    } else {
      // layer opacity to 0.6 makes layer visible
      imageryLayer.setOpacity(0.6);
      setLayers(
        layers.map((layer) => {
          if (layer.id === layerObj.id)
            return {
              ...layer,
              checked: true,
            };
          return layer;
        })
      );
    }
  };

  useEffect(() => {
    /* checking if we select new date, if new date is selected we need to set checked property to true so that button for imagery in 
    layer control has active design */
    if (selectedDate && layers?.length) {
      let updatedList = layers.map((layer) => {
        if (layer.name === "Imagery") {
          return {...layer, checked: true};
        }
        return layer;
      });
      setLayers(updatedList);
    }
  }, [selectedDate]);

  const onGroupAdd = (layer, name, group) => {
    setLayers((_layers) => [
      ..._layers,
      {
        layer,
        group,
        name,
        checked: map?.hasLayer(layer),
        id: Util.stamp(layer),
      },
    ]);
  };

  const groupedLayers = lodashGroupBy(layers, "group");

  return (
    <LayersControlProvider
      value={{
        layers,
        addGroup: onGroupAdd,
      }}
    >
      <div className={positionClass}>
        <div className="leaflet-control leaflet-bar font-roboto">
          {
            <div
              onMouseEnter={() => setCollapsed(false)}
              onMouseLeave={() => setCollapsed(true)}
              className={`layer-control ${
                collapsed ? "layer-control-collapsed" : "layer-control-open"
              } active-base-${activeBase}`}
            >
              {collapsed && <MapLayerIcon base={activeBase} />}
              {!collapsed &&
                Object.keys(groupedLayers).map((section, index) => (
                  <div
                    className={`layer-control-group-${section
                      .replace(/\s+/g, "-")
                      .toLowerCase()} base-layer-groups section-${index}`}
                    key={section}
                  >
                    {groupedLayers[section]?.map((layerObj) => (
                      <BaseMapIcon
                        checked={layerObj.checked}
                        onClick={() =>
                          section === "Base Layers"
                            ? onLayerClickRadio(layerObj)
                            : onLayerClickCheckbox(layerObj)
                        }
                        clickHandler={
                          section === "Base Layers"
                            ? onLayerClickRadio
                            : onLayerClickCheckbox
                        }
                        key={layerObj.id}
                        name={layerObj.name}
                        layerObj={layerObj}
                      />
                    ))}
                  </div>
                ))}
            </div>
          }
        </div>
        {children}
      </div>
    </LayersControlProvider>
  );
}

const GroupedLayer = createControlledLayer(function addGroup(
  layersControl,
  layer,
  name,
  group
) {
  layersControl.addGroup(layer, name, group);
});

export default LayerControl;
export {GroupedLayer};
