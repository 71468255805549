import React, {useState, useEffect} from "react";
import {
  TileLayer,
  MapContainer,
  ZoomControl,
  WMSTileLayer,
  LayerGroup,
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import Position from "../Media/Icons/Position";
import Slider from "../Media/Icons/Slider";
import "../Style/map.css";
import Header from "./Header";
import LayerSlider from "./LayerSlider";
import ImageryLoad from "./ImageryLoad";
import useSelectedDate from "../Hooks/useSelectedDate";
import useNotifications from "../Hooks/useNotifications";
import {DrawPolygon} from "./DrawPolygon";
import HeaderSlider from "./HeaderSlider";
/* import Incidents from "./Incidents"; */
import L from "leaflet";
import LayerControl, {GroupedLayer} from "./LayerControl";
import EsaCreditsComponent from "./EsaCredits";
import {stepsMap} from "../Utils/GuideSteps";
import Legend from "./Legend";
import ChangeDetectionVectors from "./ChangeDetectionVectors";
import MediaArticles from "./MediaArticles";

export default function MapLeaflet() {
  const center = [44.515856, 16.450145];
  const zoom = 7;
  const maxZoom = 18;
  const minZoom = 6;
  const maxBound = [
    {lat: 47, lng: 13},
    {lat: 47, lng: 20},
    {lat: 42, lng: 20},
    {lat: 42, lng: 13},
    {lat: 47, lng: 13},
  ];

  const {setAppState} = useNotifications();
  const [sliderActive, setSliderActive] = useState(false);
  const [mapRef, setMapRef] = useState();
  const [imageryRef, setImageryRef] = useState();
  const [positionMarker, setPositionMarker] = useState();

  const {selectedArea, setCompareImageReference} = useSelectedDate();

  const toggleSlider = () => {
    //if (selectedArea?.properties?.name)
    setSliderActive((prev) => !prev);
    // When we deactivate compare imagery, we are setting image references to null so when we come back to compare we have initial state
    if (!sliderActive) {
      setCompareImageReference((prev) => {
        return {
          ...prev,
          leftImg: null,
          rightImg: null,
        };
      });
    }
  };

  const findMe = () => {
    let circle = {};
    let newRadius = 0;
    let latlon = [];
    mapRef.target
      .locate()
      .once("locationfound", function (e) {
        if (positionMarker) mapRef.target.removeLayer(positionMarker);
        mapRef?.target.flyToBounds(e.bounds);
        latlon = [e?.latitude, e?.longitude];
        newRadius = e?.accuracy / 2;
        circle = L.circle(latlon, 1, {
          weight: 1,
          color: "blue",
          fillColor: "#cacaca",
          fillOpacity: 0.2,
        });
        mapRef.target.addLayer(circle);
      })
      .once("moveend zoomend", () => {
        let interval = setInterval(() => {
          let currentRadius = circle.getRadius();
          if (currentRadius < newRadius) {
            circle.setRadius(currentRadius + 3);
          } else {
            clearTimeout(interval);
            mapRef.target.removeLayer(circle);
            let circleMarker = L.circle(latlon, {radius: 50});
            setPositionMarker(circleMarker);
            mapRef.target.addLayer(circleMarker);
          }
        }, 1);
      });
  };

  useEffect(() => {
    const resizeMap = () => {
      if (mapRef.target) {
        setTimeout(function () {
          mapRef.target.invalidateSize();
        }, 400);
      }
    };

    window.addEventListener("resize", resizeMap);

    // Start guide on render
    let mapGuideState = JSON.parse(localStorage.getItem("mapGuideFinished"));
    if (!mapGuideState) {
      setAppState((prev) => {
        return {
          ...prev,
          run: true && prev.enabled,
          tourActive: true,
          steps: stepsMap,
          stepIndex: 0,
        };
      });
    } else {
      setAppState((prev) => {
        return {...prev, steps: stepsMap};
      });
    }

    return () => {
      window.removeEventListener("resize", resizeMap);
    };
  }, [mapRef]);

  return (
    <>
      <div className="font-roboto relative h-full overflow-hidden">
        <div className="z-0 h-full overflow-hidden">
          <MapContainer
            center={center}
            zoom={zoom}
            maxZoom={maxZoom}
            minZoom={minZoom}
            zoomControl={false}
            className="h-full"
            whenReady={setMapRef}
            maxBounds={maxBound}
          >
            <div className="relative wrapper">
              <div className="absolute block top-0 right-0 mr-2.5 parent-slider-btn">
                <button
                  title="Compare"
                  className={`slider-btn block w-10 h-10 bg-white disabled:hover:bg-white hover:bg-blue-100 ${
                    !sliderActive === false ? "slider-btn-active" : ""
                  }`}
                  onClick={toggleSlider}
                  disabled={!selectedArea?.name ? true : false}
                >
                  <Slider />
                </button>
              </div>
              <ZoomControl position="topright" />
              <div className="parent-position-download-btn absolute block mr-2.5 ">
                <button
                  title="Find me"
                  className="position-download-btn block w-10 h-10 bg-white hover:bg-blue-100"
                  onClick={findMe}
                >
                  <Position />
                </button>
                {/* <button
                className="position-download-btn block w-10 h-10 bg-white hover:bg-blue-100"
                onClick={() => {
                  setMessage("Test message", "error");
                }}
              >
                <PDFDownload />
              </button> */}
              </div>
            </div>
            {!sliderActive && (
              <LayerControl position="bottomleft">
                <GroupedLayer checked name="OSM" group="Base Layers">
                  <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  />
                </GroupedLayer>

                <GroupedLayer name="DOF" group="Base Layers">
                  <WMSTileLayer
                    format="image/png"
                    layers="OI.OrthoImagery"
                    url="https://geoportal.dgu.hr/services/inspire/orthophoto_2014-2016/wms"
                  />
                </GroupedLayer>
                <GroupedLayer name="Detections" group="Overlay" checked>
                  {/* <Incidents /> */}
                </GroupedLayer>
                <GroupedLayer name="Imagery" group="Overlay" checked>
                  <LayerGroup attribution="Sentinel" ref={setImageryRef}>
                    <ImageryLoad group={imageryRef} />
                  </LayerGroup>
                </GroupedLayer>
              </LayerControl>
            )}
            {sliderActive && <LayerSlider sliderActive={sliderActive} />}
          </MapContainer>
          {!sliderActive && <Header mapRef={mapRef} />}
          {sliderActive && <HeaderSlider />}
          <DrawPolygon mapRef={mapRef} />
          {!sliderActive && <ChangeDetectionVectors mapRef={mapRef} />}
        </div>
        <Legend />
        <EsaCreditsComponent darkLogo={true} />
      </div>
      <MediaArticles mapRef={mapRef} />
    </>
  );
}
