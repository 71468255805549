import React from "react";

const SunnyIcon = () => {
  return (
    <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="m6.69 18.16-1.73 1.72-1.34-1.34 1.72-1.73 1.35 1.35Zm6.26 1.39v2.85h-1.9v-2.85h1.9ZM7.793 7.293a5.95 5.95 0 1 1 8.414 8.414 5.95 5.95 0 0 1-8.414-8.414ZM22.95 10.55v1.9h-2.9v-1.9h2.9Zm-2.57 7.99-1.34 1.34-1.73-1.72 1.35-1.35 1.72 1.73Zm-3.07-13.7 1.73-1.72 1.34 1.34-1.72 1.73-1.35-1.35ZM12.95.6v2.85h-1.9V.6h1.9ZM1.05 12.45v-1.9h2.9v1.9h-2.9Zm3.91-9.33 1.73 1.72-1.35 1.35-1.72-1.73 1.34-1.34Z"
        fill="#FFD233"
        stroke="#666977"
        strokeWidth=".1"
      />
    </svg>
  );
};

export default SunnyIcon;
