import React, {createContext, useState, useEffect} from "react";
import useNotifications from "../Hooks/useNotifications";
import axios from "../Services/axios";
import defaultAxios from "axios";
import {convertDate} from "../Utils/DateFunctions";
import {trackPromise} from "react-promise-tracker";

const SelectedDateContext = createContext({});

const datePlaceholder = "--/--/----";
// Extension of base URL which is the same for all requests based on change-detection
const BASE_URL_EXTENT = "change-detection/responsible-areas";

export const SelectedDateProvider = ({children}) => {
  const {setMessage} = useNotifications();

  const [selectedDate, setSelectedDate] = useState({});
  const [referenceDate, setReferenceDate] = useState({});
  const [availableDates, setAvailableDates] = useState(
    Array(6).fill({date: datePlaceholder})
  );
  const [availableAreas, setAvailableAreas] = useState([]);
  const [selectedArea, setSelectedArea] = useState({});
  const [compareImageReference, setCompareImageReference] = useState({
    leftImg: null,
    rightImg: null,
  });
  const [mobileDatesRange, setMobileDatesRange] = useState({start: 0, end: 4, len: 4});
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [northEast, setNorthEast] = useState();
  const [southWest, setSouthWest] = useState();
  // Object which holds variables for fetching available dates
  const [fetchNewAvailableDates, setFetchNewAvailableDates] = useState({
    forward: null, // looking if there is more items to fetch (data.previous in response)
    backward: null, // looking if there is more items to fetch (data.next in response)
    pagination: 1, // triggers useEffect for fetching new available dates
    fetchDirection: null, // saves value for next fetch (backward or forward)
    firstRender: true, // if value is true, for the first render of selected location make initial fetch of data
    indexOfSelectedDate: null, // index of selected date so we can set view on that date inside Map page header carousel
  });

  const handleNewDates = (response) => {
    // All available dates for selected location with raster tileset URL inside response
    let newDates = response.data.results.reverse().map((element, idx) => {
      let transformedDate = convertDate(element.target_image.date_time);
      let transformedReferenceDate = convertDate(element.reference_image.date_time);
      return {
        ...element,
        date: transformedDate,
        reference_date: transformedReferenceDate,
        index: idx + 1,
      };
    });
    // Set available dates
    setAvailableDates(newDates);

    // Set first date after fetch for tileLayer display - triggers useEffect in ImageryLoad.js
    if (
      !fetchNewAvailableDates.fetchDirection ||
      fetchNewAvailableDates.fetchDirection === "backward"
    ) {
      setSelectedDate(newDates[newDates.length - 1]);
      setFetchNewAvailableDates((prev) => {
        return {
          ...prev,
          indexOfSelectedDate: newDates[newDates.length - 1]?.index,
        };
      });
      setMobileDatesRange((prev) => {
        return {
          ...prev,
          start: newDates.length - 4,
          end: newDates.length < 20 ? newDates.length : 20,
          len: newDates.length,
        };
      });
    } else if (fetchNewAvailableDates.fetchDirection === "forward") {
      setSelectedDate(newDates[0]);
      setFetchNewAvailableDates((prev) => {
        return {
          ...prev,
          indexOfSelectedDate: newDates[0]?.index,
        };
      });
      setMobileDatesRange((prev) => {
        return {
          ...prev,
          start: 0,
          end: newDates.length <= 4 ? newDates.length : 4,
          len: newDates.length,
        };
      });
    }
  };

  // Set initial availableAreas - on first load or if state cleaned
  useEffect(() => {
    const fetchAreas = async () => {
      try {
        const response = await axios.get(BASE_URL_EXTENT);

        let areas = response.data.results.map((element) => {
          return {...element, id: element.id};
        });
        setAvailableAreas(areas);
      } catch (err) {
        if (!err?.response) {
          setMessage("No server response!", "error");
        } else if (err.response?.status === 401) {
          setMessage("Unauthorized", "error");
        } else {
          setMessage("Something went wrong!", "error");
        }
      }
    };
    if (availableAreas.length === 0) fetchAreas();
  }, [availableAreas]);

  // On selected area change, set values of fetchNewAvailableDates object to default values
  useEffect(() => {
    if (selectedArea?.id) {
      setFetchNewAvailableDates((prev) => {
        return {
          ...prev,
          forward: null,
          backward: null,
          pagination: 1,
          fetchDirection: null,
          firstRender: true,
          indexOfSelectedDate: null,
        };
      });
    }
  }, [selectedArea]);

  useEffect(() => {
    if (selectedArea?.id) {
      const fetchDatesOnFirstRender = async () => {
        try {
          // Fetch responsible area data for location that was selected on first render
          if (fetchNewAvailableDates.firstRender) {
            let response = await axios.get(
              `${BASE_URL_EXTENT}/${selectedArea.id}/detections/`
            );
            setFetchNewAvailableDates((prev) => {
              return {
                ...prev,
                firstRender: false,
                backward: response.data.next,
                forward: response.data.previous,
              };
            });
            handleNewDates(response);
          }
        } catch (err) {
          if (!err?.response) {
            setMessage("No server response!", "error");
          } else if (err.response?.status === 401) {
            setMessage("Unauthorized", "error");
          } else {
            setMessage("Something went wrong!", "error");
          }
        }
      };

      trackPromise(fetchDatesOnFirstRender());
    }
  }, [fetchNewAvailableDates]);

  useEffect(() => {
    // Fetch responsible area data for location that was selected on pagination change
    if (!fetchNewAvailableDates.firstRender) {
      const fetchDatesOnPaginationChange = async () => {
        try {
          if (fetchNewAvailableDates.fetchDirection === "backward") {
            let response = await defaultAxios.get(fetchNewAvailableDates.backward);
            setFetchNewAvailableDates((prev) => {
              return {
                ...prev,
                backward: response.data.next,
                forward: response.data.previous,
              };
            });
            handleNewDates(response);
          }
          if (fetchNewAvailableDates.fetchDirection === "forward") {
            let response = await defaultAxios.get(fetchNewAvailableDates.forward);
            setFetchNewAvailableDates((prev) => {
              return {
                ...prev,
                backward: response.data.next,
                forward: response.data.previous,
              };
            });
            handleNewDates(response);
          }
        } catch (err) {
          if (!err?.response) {
            setMessage("No server response!", "error");
          } else if (err.response?.status === 401) {
            setMessage("Unauthorized", "error");
          } else {
            setMessage("Something went wrong!", "error");
          }
        }
      };

      trackPromise(fetchDatesOnPaginationChange());
    }
  }, [fetchNewAvailableDates.pagination]);

  return (
    <SelectedDateContext.Provider
      value={{
        selectedDate,
        setSelectedDate,
        referenceDate,
        setReferenceDate,
        availableDates,
        setAvailableDates,
        selectedArea,
        setSelectedArea,
        availableAreas,
        setAvailableAreas,
        compareImageReference,
        setCompareImageReference,
        mobileDatesRange,
        setMobileDatesRange,
        startDate,
        setStartDate,
        endDate,
        setEndDate,
        northEast,
        setNorthEast,
        southWest,
        setSouthWest,
        fetchNewAvailableDates,
        setFetchNewAvailableDates,
      }}
    >
      {children}
    </SelectedDateContext.Provider>
  );
};

export default SelectedDateContext;
