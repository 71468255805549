import React, {useEffect, useRef, useState} from "react";
import "../Style/login.css";
import Logo from "../Media/Icons/Logo";
import axios from "../Services/axios";
import useAuth from "../Hooks/useAuth";
import {useNavigate, useLocation} from "react-router-dom";
import EsaCreditsComponent from "./EsaCredits";
import useNotifications from "../Hooks/useNotifications";

const LOGIN_URL = "user/token/";

const LoginPage = () => {
  const {setAuth, persist, setPersist} = useAuth();

  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";

  const usernameRef = useRef();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const {setMessage} = useNotifications();

  useEffect(() => {
    usernameRef.current.focus();
  }, []);

  useEffect(() => {
    setMessage("");
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const bodyFormData = new FormData();
    bodyFormData.append("username", username);
    bodyFormData.append("password", password);
    try {
      const response = await axios.post(LOGIN_URL, bodyFormData, {
        headers: {"Content-Type": "application/json"},
        withCredentials: true,
      });

      //console.log(JSON.stringify(response.data));

      const access = response?.data?.access;
      setAuth({access});
      setPassword("");
      setUsername("");

      if (!persist) sessionStorage.setItem("accessToken", JSON.stringify(access));

      navigate(from, {replace: true});
    } catch (err) {
      if (!err?.response) {
        setMessage("No server response!", "error");
      } else if (err.response?.status === 400) {
        setMessage("Missing username or password", "error");
      } else if (err.response?.status === 401) {
        setMessage("Unauthorized", "error");
      } else {
        setMessage("Something went wrong!", "error");
      }
    }
  };

  const togglePersist = () => {
    setPersist((prev) => !prev);
  };

  useEffect(() => {
    localStorage.setItem("persist", persist);
  }, [persist]);

  return (
    <div className="relative h-full font-roboto overflow-auto sm:flex sm:justify-center sm:items-center login-wrapper select-none">
      <div className="card-login pt-16 pb-22 sm:py-12 items-start sm:items-center">
        <form onSubmit={handleSubmit}>
          <Logo className="logo" />
          <div className="text-white text-l text-center">Welcome back!</div>
          <div className="text-white text-center text-xs mb-9">
            Login with the credentials provided to you by the administrator.
          </div>
          <div className="w-full text-white help-text">Username</div>
          <input
            className="login-style"
            placeholder="Username"
            type="text"
            name="username"
            autoFocus
            autoCapitalize="none"
            maxLength="150"
            ref={usernameRef}
            onChange={(e) => setUsername(e.target.value)}
            value={username}
          ></input>
          <div className="w-full text-white help-text">Password</div>
          <input
            className="login-style"
            placeholder="Password"
            type="password"
            name="password"
            autoFocus
            autoCapitalize="none"
            maxLength="150"
            onChange={(e) => setPassword(e.target.value)}
            value={password}
          ></input>
          <div className="w-full checkbox-login text-gray-200">
            <input
              className="checkbox-login"
              type="checkbox"
              onChange={togglePersist}
              checked={persist}
            ></input>
            <p className="ml-1 text-white">Remember me</p>
          </div>
          <button className="btn-login bg-blue-100 text-white hover:bg-blue-200">
            Login
          </button>
        </form>
      </div>
      <EsaCreditsComponent />
    </div>
  );
};

export default LoginPage;
