import * as React from "react";

const CloudIcon = (props) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M5.034 11.117A4.002 4.002 0 0 0 6 19h11a5.002 5.002 0 0 0 4.285-7.571 5 5 0 0 0-5.455-2.291L14.5 9.5"
      stroke="#454545"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
    <path
      d="M15.83 9.138a5.5 5.5 0 0 0-10.796 1.98S5.187 12 5.5 12.5"
      stroke="#454545"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
  </svg>
);

export default CloudIcon;
