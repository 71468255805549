import React, {useState, useEffect, useRef} from "react";
import {useLocation} from "react-router-dom";
import {Outlet} from "react-router-dom";
import Sidebar from "./Sidebar";
import "../Style/layout.css";
import useScrollListener from "../Hooks/useScrollListener";

const getWidth = () =>
  window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

const Layout = () => {
  const mainWrapper = useRef();
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const location = useLocation().pathname;
  const deviceWidth = window.matchMedia("(max-width: 1024px)");
  const scroll = useScrollListener(mainWrapper);
  const [navClassList, setNavClassList] = useState([]);

  useEffect(() => {
    const _classList = [];
    if (scroll.y > 0 && getWidth() < 640) {
      setSidebarOpen(false);
    }

    if (scroll.y > 60 && scroll.y - scroll.lastY > 0) {
      _classList.push("hide-on-scroll");
    }

    setNavClassList(_classList);
  }, [scroll.y, scroll.lastY]);

  useEffect(() => {
    setSidebarState(getWidth());
    const resizeListener = () => {
      setSidebarState(getWidth());
    };
    window.addEventListener("resize", resizeListener);

    if (deviceWidth.matches) {
      setHeight();
    }
    return () => {
      window.removeEventListener("resize", resizeListener);
    };
  }, [location]); //close mobile nav on every route change

  const setSidebarState = (windowWidth) => {
    if (windowWidth > 639) {
      setSidebarOpen(true);
    } else {
      setSidebarOpen(false);
    }
  };

  const setHeight = () => {
    mainWrapper.current.style.maxHeight = window.innerHeight + "px";
  };

  const toggleSidebar = () => {
    setSidebarOpen((sidebarOpen) => !sidebarOpen);
  };

  const handleSidebar = () => {
    if (getWidth() < 640) {
      setSidebarOpen(false);
    } else {
      return;
    }
  };

  return (
    <main className="App relative bg-cover bg-left">
      <button
        className={`
          ${navClassList}
          ${location === "/map" || location === "/dashboard" ? "blue-logo" : ""}
          ${
            sidebarOpen ? "opened" : ""
          } sm:hidden menu-button absolute left-0 top-0 pb-6 rounded-xl rounded-tl-none`}
        onClick={toggleSidebar}
      >
        <div className="bar pointer-events-none"></div>
        <div className="bar pointer-events-none"></div>
        <div className="bar pointer-events-none"></div>
      </button>
      <Sidebar
        onClickOutside={() => {
          handleSidebar();
        }}
        isOpen={sidebarOpen}
      />
      <div
        ref={mainWrapper}
        className={`${
          sidebarOpen ? "sidebar-open" : "ml-0"
        } relative h-screen main-content-wrapper overflow-auto main-target`}
      >
        <Outlet />
      </div>
    </main>
  );
};

export default Layout;
