import React, {useEffect, useState} from "react";
import MUIDataTable from "mui-datatables";
import {CacheProvider} from "@emotion/react";
import createCache from "@emotion/cache";
import {ThemeProvider, createTheme} from "@mui/material/styles";
import moment from "moment";
import useAxiosPrivate from "../Hooks/useAxiosPrivate";
import {trackPromise} from "react-promise-tracker";
import DashboardLocate from "../Media/Icons/DashboardLocate";
import useSelectedDate from "../Hooks/useSelectedDate";
import {convertDate} from "../Utils/DateFunctions";
import {useNavigate, useLocation} from "react-router-dom";

import "../Style/detections_table.css";

const muiCache = createCache({
  key: "mui-datatables",
  prepend: true,
});

const tableOptions = {
  filter: false,
  viewColumns: false,
  print: true,
  selectableRows: "none",
  textLabels: {
    body: {
      noMatch: "No matching records for selected dates and location",
    },
  },
  rowsPerPageOptions: [0], // it was object before, but it was causing err in console because it needs to be a number
};

const tableTheme = createTheme({
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: "none",
        },
      },
    },
    MUIDataTableBodyCell: {
      styleOverrides: {
        root: {
          backgroundColor: "#fff",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          padding: "6px 0",
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottom: "1px solid #f2f2f2",
          color: "#6B6F7B",
          padding: "16px 10px",
        },
      },
    },
    MUIDataTableHeadCell: {
      styleOverrides: {
        root: {
          color: "#022859",
          padding: "16px 10px",
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          color: "#6B6F7B",
        },
      },
    },
    MUIDataTableToolbar: {
      styleOverrides: {
        icon: {
          "&:hover": {
            color: "#63A6FD",
          },
        },
      },
    },
  },
});

const DATA_URL = "change-detection/responsible-areas";

export function DetectionsTableData({location, start, end, hasdata}) {
  const [tableData, setTableData] = useState([]);
  const axiosPrivate = useAxiosPrivate();
  const {setSelectedDate, availableDates} = useSelectedDate();

  const navigate = useNavigate();
  const locationPath = useLocation();

  /* const handleLocate = (value, tableMeta) => {
    let date = new Date(tableMeta.rowData[2]);
    // Increment 1 day because of difference - sometimes !
    date.setDate(date.getDate() + 1);
    let dateNormalized = convertDate(date);
    let selDate = availableDates.filter((elem) => {
      return dateNormalized === elem.date;
    });
    setSelectedDate(selDate[0]);
    setTimeout(() => {
      navigate("/map", {replace: true, prevPath: locationPath.pathname});
    }, 50);
  }; */

  const tableColumns = [
    {name: "Location"},
    {name: "Area"},
    {
      name: "Date",
      options: {
        // eslint-disable-next-line no-unused-vars
        setCellProps: (value) => {
          return {
            className: "date-cell",
          };
        },
      },
    },
    {name: "Probability"},
    {name: "Class"},
    {
      name: "Status",
      options: {
        setCellProps: (value) => {
          return {
            className: `${value?.toLowerCase()} status`,
          };
        },
      },
    },
    {
      name: "Map",
      options: {
        display: "excluded",
        /* sort: false,
        // eslint-disable-next-line no-unused-vars
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div
              onClick={() => handleLocate(value, tableMeta)}
              className="dashboard-locate"
            >
              <DashboardLocate />
            </div>
          );
        }, */
      },
    },
  ];

  useEffect(() => {
    // select Area column label in HTML so we can add m^2 to it's name
    // we are doing this because we can't use HTML elements inside "name" property of tableColumns
    const tableHeaderLabels = document.querySelectorAll(
      ".tss-1akey0g-MUIDataTableHeadCell-data"
    );
    const areaColumnLabel = tableHeaderLabels[1];
    areaColumnLabel.innerHTML = "Area (m<sup>2</sup>)";
  }, []);

  useEffect(() => {
    if (location && start && end) {
      getTableData();
      hasdata(true);
    } else {
      hasdata(false);
    }
  }, [location, start, end]);

  // it was used when we were using data directly from backend and when we didn't prepare each value separately
  /* const swapTableRowPositions = (array, a, b) => {
    [array[a], array[b]] = [array[b], array[a]];
  }; */

  const getTableData = () => {
    setTableData([]);
    if (start && end && location) {
      const fromDate = moment(start).format("YYYY-MM-DD");
      const endDate = moment(end).clone().endOf("month").format("YYYY-MM-DD");

      trackPromise(
        axiosPrivate.get(`${DATA_URL}/${location}/data`, {
          params: {
            start_date: fromDate,
            end_date: endDate,
            data_type: "table",
          },
        })
      ).then((res) => {
        const tableDataResponse = res.data.data;
        if (tableDataResponse) {
          let arrayTableData = [];
          tableDataResponse.forEach((el) => {
            let rowData = [];
            for (const [key, value] of Object.entries(el)) {
              if (key === "area") {
                rowData[1] = Math.round(value);
              } else if (key === "date") {
                rowData[2] = value;
              }
            }
            rowData[0] = res.data.area;
            arrayTableData.push(rowData);
            // calling helper function above, we don't need it anymore because we prepare each value separately
            /* swapTableRowPositions(rowData, 0, 1);*/
          });
          setTableData(arrayTableData);
        }
      });
    }
  };

  return (
    <CacheProvider value={muiCache}>
      <ThemeProvider theme={tableTheme}>
        <div className="pb-4 px-2 detections-table relative">
          <MUIDataTable data={tableData} columns={tableColumns} options={tableOptions} />
        </div>
      </ThemeProvider>
    </CacheProvider>
  );
}
