import React from "react";

const JoyRideMapIcon = () => {
  return (
    <svg width="60" height="54" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M57.808 5.205 39.2 0 20.537 5.257 3.183.334A2.5 2.5 0 0 0 0 2.739v42.575A3.012 3.012 0 0 0 2.181 48.2l18.352 5.206 18.67-5.259 17.624 4.93A2.5 2.5 0 0 0 60 50.668V8.094a3.01 3.01 0 0 0-2.192-2.889Zm-39.433 43.43L4 44.557V4.723l14.375 4.079v39.834Zm18.827-4.08L22.375 48.73V8.895l14.827-4.176v39.836ZM56 48.69l-14.798-4.139V4.713L56 8.853V48.69Z"
        fill="#63A6FD"
      />
    </svg>
  );
};

export default JoyRideMapIcon;
