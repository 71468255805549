import React, {useEffect, useRef, useState} from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import {Line} from "react-chartjs-2";
import useAxiosPrivate from "../Hooks/useAxiosPrivate";
import moment from "moment";
import {trackPromise} from "react-promise-tracker";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

function createGradient(ctx, area) {
  const colorEnd = "rgba(55, 159, 255, 0.5)";
  const colorMid = "rgba(171, 215, 255, 0.5)";
  const colorStart = "rgba(255, 255, 255, 0.1)";

  const gradient = ctx.createLinearGradient(0, area.bottom, 0, area.top);

  gradient.addColorStop(0, colorStart);
  gradient.addColorStop(0.5, colorMid);
  gradient.addColorStop(1, colorEnd);

  return gradient;
}

const DATA_URL = "change-detection/responsible-areas";

export function Chart({location, start, end, chartType, label, chartStepSize}) {
  const axiosPrivate = useAxiosPrivate();
  const chartRef = useRef();
  const [chartData, setChartData] = useState({datasets: []});
  const data = {
    labels: [],
    datasets: [
      {
        label: label,
        data: [],
        fill: true,
        backgroundColor: "rgba(55, 159, 255, 0.5",
        borderColor: "#3B82F6",
        pointBackgroundColor: "#fff",
        pointHoverBackgroundColor: "#fff",
        lineTension: 0.3,
        showLine: true,
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
        position: "top",
      },
      title: {
        display: false,
      },
    },
    scales: {
      x: {
        grid: {
          display: true,
          color: "#8a88881a",
        },
      },
      y: {
        ticks: {
          stepSize: chartStepSize,
        },
        grid: {
          display: true,
          color: "#8a88881a",
        },
      },
    },
  };

  useEffect(() => {
    if (location && start && end) {
      getChartData();
    }
  }, [location, start, end]);

  const getChartData = () => {
    setChartData(data);
    if (start && end && location) {
      const fromDate = moment(start).format("YYYY-MM-DD");
      const endDate = moment(end).clone().endOf("month").format("YYYY-MM-DD");

      trackPromise(
        axiosPrivate.get(`${DATA_URL}/${location}/data`, {
          params: {
            start_date: fromDate,
            end_date: endDate,
            data_type: chartType,
          },
        })
      ).then((res) => {
        const chartDataFeatures = res.data;

        if (chartDataFeatures) {
          let labels = Object.keys(chartDataFeatures).reverse();
          let newData = Object.values(chartDataFeatures).reverse();

          const chart = chartRef.current;

          if (!chart) {
            return;
          }

          const chartData = {
            ...data,
            labels: labels,
            datasets: data.datasets.map((dataset) => ({
              ...dataset,
              data: newData,
              backgroundColor: createGradient(chart.ctx, chart.chartArea),
            })),
          };

          setChartData(chartData);
        }
      });
    }
  };

  return (
    <div style={{height: "30vh", width: "100%"}} className="lg:mt-auto">
      <Line ref={chartRef} options={options} data={chartData} />
    </div>
  );
}
