import React from "react";

const ForwardIcon = () => {
  return (
    <svg width="20" height="20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.75 10a8.75 8.75 0 1 0-17.5 0 8.75 8.75 0 0 0 17.5 0ZM0 10a10 10 0 1 1 20 0 10 10 0 0 1-20 0Zm5.625.625a.625.625 0 1 1 0-1.25h7.241l-2.684-2.683a.627.627 0 0 1 .886-.885l3.75 3.75a.625.625 0 0 1 0 .886l-3.75 3.75a.626.626 0 1 1-.886-.886l2.684-2.682H5.625Z"
        fill="#63A6FD"
      />
    </svg>
  );
};

export default ForwardIcon;
