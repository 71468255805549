import {useEffect} from "react";
import {useMap} from "react-leaflet";
/* import parseGeoraster from "georaster";
import GeoRasterLayer from "georaster-layer-for-leaflet"; */
import useSelectedDate from "../Hooks/useSelectedDate";
/* import useNotifications from "../Hooks/useNotifications";
import {colorList} from "../Utils/ImageryColors"; */
import L from "leaflet";

const ImageryLoad = ({group}) => {
  const {selectedDate, northEast, setNorthEast, southWest, setSouthWest} =
    useSelectedDate();
  const map = useMap();

  /* useEffect(() => {
    // Polygon bounds - console error solved
    // We only have tiles for raster tileset image, we need bounds because we are trying to get image only for that area, not for whole map view on our screen
    if (map) {
      map.eachLayer((layer) => {
        if (layer.polygonLabel === "polygonGeoJson") {
          setNorthEast(layer._bounds._northEast);
          setSouthWest(layer._bounds._southWest);
        }
      });
    }
  }, [map, selectedDate, setNorthEast, setSouthWest]); */

  useEffect(() => {
    if (selectedDate?.raster_tileset_url) {
      let tileLayer = L.tileLayer(`${selectedDate.raster_tileset_url}/{z}/{x}/{y}.png`, {
        opacity: 0.6,
        // setting z-index because when we change base layer tileLayer disappears
        zIndex: 10,
        /* bounds: L.latLngBounds(northEast, southWest), */
      });

      map.eachLayer((layer) => {
        if (layer.options.attribution === "Sentinel") {
          layer.addLayer(tileLayer);
        }
      });
    }

    return () => {
      map.eachLayer((layer) => {
        if (layer.options.attribution === "Sentinel") {
          layer.eachLayer((groupLayer) => {
            layer.removeLayer(groupLayer);
          });
        }
      });
    };
  }, [group, selectedDate]);

  return;
};

export default ImageryLoad;
