import React from "react";
import ESAwhite from "../Media/Images/ESAwhite.svg";
import ESAdark from "../Media/Images/ESAdark.svg";

const EsaCreditsComponent = ({darkLogo}) => {
  return (
    <div className="absolute block bottom-5 sm:bottom-5 right-0 esacredit text-xs text-right">
      <img
        className="block esacredit float-right"
        alt="European Space Agency"
        src={darkLogo ? ESAdark : ESAwhite}
      />
      <p
        className={`block text-xxs sm:text-xs esacredit font-bold text-right clear-both mr-6
          ${darkLogo ? "text-blue-300" : "text-white"}`}
      >
        Project supported by ESA Network of Resources Initiative
      </p>
    </div>
  );
};

export default EsaCreditsComponent;
