import React from "react";

const ForwardMobileIcon = () => {
  return (
    <svg width="40" height="40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.5 20a17.5 17.5 0 1 0-35 0 17.5 17.5 0 0 0 35 0ZM0 20a20 20 0 1 1 40 0 20 20 0 0 1-40 0Zm11.25 1.25a1.25 1.25 0 0 1 0-2.5h14.483l-5.368-5.365a1.252 1.252 0 0 1 1.77-1.77l7.5 7.5a1.25 1.25 0 0 1 0 1.77l-7.5 7.5a1.252 1.252 0 0 1-1.77-1.77l5.368-5.365H11.25Z"
        fill="#63A6FD"
      />
    </svg>
  );
};

export default ForwardMobileIcon;
