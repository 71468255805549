import React from "react";

const MapSmall = () => {
  return (
    <svg width="30" height="27" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M28.904 2.603 19.6 0l-9.331 2.628L1.59.167A1.25 1.25 0 0 0 0 1.369v21.288A1.506 1.506 0 0 0 1.09 24.1l9.177 2.603 9.335-2.63 8.811 2.465A1.25 1.25 0 0 0 30 25.334V4.047a1.505 1.505 0 0 0-1.096-1.444ZM9.188 24.317 2 22.278V2.363L9.188 4.4v19.917Zm9.413-2.04-7.413 2.088V4.447L18.6 2.36v19.918ZM28 24.345l-7.4-2.07V2.356l7.4 2.07v19.92Z"
        fill="#63A6FD"
      />
    </svg>
  );
};

export default MapSmall;
