import {useState, useEffect} from "react";
import ReactDOMServer from "react-dom/server";
import {useLeafletContext} from "@react-leaflet/core";
import L, {tileLayer} from "leaflet";
import sideBySide from "../Utils/SideBySide";
import useSelectedDate from "../Hooks/useSelectedDate";
import useNotifications from "../Hooks/useNotifications";
import {stepsSlider} from "../Utils/GuideSteps";
import axios from "../Services/axios";
import Marker from "../Media/Icons/marker";

import "../Style/layer_slider.css";

const BASE_URL_EXTENT = "change-detection/responsible-areas";

const LayerSlider = ({sliderActive}) => {
  const context = useLeafletContext();
  const {compareImageReference, setCompareImageReference, selectedArea} =
    useSelectedDate();
  const container = context.layerContainer || context.map;
  const [sbs, setSbs] = useState();
  /* This is probabbly obsolete */
  const [leftLayer, setLeftLayer] = useState();
  const [rightLayer, setRightLayer] = useState();
  const {setMessage, setAppState} = useNotifications();

  useEffect(() => {
    const resizeMap = () => {
      setTimeout(function () {
        container.invalidateSize();
      }, 400);
    };
    resizeMap();
    let l1 = new L.Layer();
    let l2 = new L.Layer();
    let control = L.control.sideBySide(l1, l2);
    control.addTo(container);
    setSbs(control);
    window.addEventListener("resize", resizeMap);

    let DOF = L.tileLayer.wms(
      "https://geoportal.dgu.hr/services/inspire/orthophoto_2014-2016/wms",
      {
        maxZoom: 19,
        layers: "OI.OrthoImagery",
      }
    );

    DOF.addTo(container);

    // Guide logic
    let sliderGuideState = JSON.parse(localStorage.getItem("sliderGuideFinished"));
    if (!sliderGuideState) {
      setAppState((prev) => {
        return {
          ...prev,
          run: true && prev.enabled,
          tourActive: true,
          steps: stepsSlider,
          stepIndex: 0,
        };
      });
    } else {
      setAppState((prev) => {
        return {...prev, steps: stepsSlider};
      });
    }

    return () => {
      container.removeLayer(DOF);
      window.removeEventListener("resize", resizeMap);
      // console.log("Unmounting controll");
      container.removeControl(control);
      if (leftLayer) {
        // console.log("Unmounting left");
        container.removeLayer(leftLayer);
      }
      if (rightLayer) {
        // console.log("Unmounting right");
        container.removeLayer(rightLayer);
      }

      setAppState((prev) => {
        return {...prev, run: false, tourActive: false, stepIndex: 0};
      });
    };
  }, []);

  useEffect(() => {
    let layer = "";
    // console.log("Try to add right");
    if (compareImageReference?.rightImg && sbs) {
      layer = new tileLayer(
        `${compareImageReference.rightImg.raster_tileset_url}/{z}/{x}/{y}.png`
      );
      layer.addTo(container);
      setRightLayer(layer);
      sbs.setRightLayers(layer);
    }
    return () => {
      // console.log("Unmounting right");
      if (layer) container.removeLayer(layer);
    };
  }, [compareImageReference.rightImg, sbs]);

  useEffect(() => {
    let layer = "";
    // console.log("Try to add left");
    if (compareImageReference?.leftImg && sbs) {
      layer = new tileLayer(
        `${compareImageReference.leftImg.raster_tileset_url}/{z}/{x}/{y}.png`
      );
      layer.addTo(container);
      setLeftLayer(layer);
      sbs.setLeftLayers(layer);
    }
    return () => {
      // console.log("Unmounting left");
      if (layer) container.removeLayer(layer);
    };
  }, [compareImageReference.leftImg, sbs]);

  const MarkerIcon = new L.divIcon({
    className: "detection-marker",
    iconAnchor: [11, 32],
    labelAnchor: [-6, 0],
    popupAnchor: [0, -36],
    html: ReactDOMServer.renderToString(<Marker markercolor={"red"} />),
  });

  // Creating vectors inside compare imagery map view
  useEffect(() => {
    if (compareImageReference?.rightImg?.id && sliderActive) {
      const fetchIncidentsCompareMap = async () => {
        const response = await axios.get(
          `${BASE_URL_EXTENT}/${selectedArea.id}/cd-vectors/`,
          {
            params: {
              reference_image_id: compareImageReference?.leftImg?.id,
              target_image_id: compareImageReference?.rightImg?.id,
            },
          }
        );

        if (response) {
          response.data.map((layer) => {
            let coordinates = layer.point.geometry.coordinates;
            let marker = L.marker(coordinates.reverse(), {icon: MarkerIcon});
            marker.addTo(container);

            // adding not-clickable class to layer so we can add cursor: grab; css property
            marker._icon.classList.add("not-clickable");

            return null;
          });
        }
      };

      fetchIncidentsCompareMap();
    }

    return () => {
      container?.eachLayer(function (layer) {
        if (layer instanceof L.Marker) {
          container.removeLayer(layer);
        }
      });
    };
  }, [compareImageReference.rightImg, sliderActive]);

  return;
};
export default LayerSlider;
