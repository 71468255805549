import React from "react";
import "../Style/legend.css";
import {colorList} from "../Utils/ImageryColors";

const Legend = () => {
  return (
    <div className="absolute text-xs text-right legend-wrapper">
      <div className="low-high-wrapper">
        <p>Low change probability</p>
        <p>High</p>
      </div>
      <div className="color-wrapper">
        {colorList.map((element, idx) => {
          return (
            <div
              className="legend-color"
              style={{backgroundColor: element}}
              key={`color-${idx}`}
            ></div>
          );
        })}
      </div>
    </div>
  );
};

export default Legend;
