import {useEffect, useState} from "react";

import L from "leaflet";

import useSelectedDate from "../Hooks/useSelectedDate";
import useNotifications from "../Hooks/useNotifications";

export const DrawPolygon = ({mapRef}) => {
  const {selectedArea} = useSelectedDate();
  const [polygonData, setPolygonData] = useState();
  const {setAppState} = useNotifications();

  // Setting polygon data on location select
  useEffect(() => {
    if (selectedArea?.geom?.coordinates) {
      selectedArea.geom.coordinates.map((element) => {
        setPolygonData([
          {
            type: "Feature",
            properties: {
              name: selectedArea.name,
            },
            geometry: {
              type: "Polygon",
              coordinates: element,
            },
          },
        ]);
        return null;
      });
    }

    if (mapRef?.target && selectedArea?.geom) {
      // Making leaflet polygon based on selected area coordinates
      let polygonBounds = L.polygon([selectedArea.geom.coordinates[0]]);
      // Getting center of the polygon (object)
      let polygonCenterObj = polygonBounds.getBounds().getCenter();
      // Converting object into array and reversing array values
      let polygonCenterArr = Object.values(polygonCenterObj).reverse();

      // Fly to selected area and set map view to the center of the polygon at zoom level 16
      mapRef?.target.flyTo(polygonCenterArr, 16);

      let guideFinished = localStorage.getItem("mapGuideFinished");
      if (!(guideFinished === "true")) {
        setAppState((prev) => {
          return {...prev, stepIndex: 2, run: true && prev.enabled};
        });
      }
    }
  }, [mapRef, selectedArea]);

  useEffect(() => {
    if (mapRef?.target && polygonData) {
      let polygon = L.geoJson(polygonData, {
        onEachFeature: function (feature, layer) {
          layer.polygonLabel = "polygonGeoJson";
        },
      });
      polygon.addTo(mapRef.target);

      // adding not-clickable class to layer so we can add cursor: grab; css property
      mapRef?.target?.eachLayer(function (layer) {
        if (layer.polygonLabel && layer.polygonLabel === "polygonGeoJson") {
          layer._path.classList.add("not-clickable");
        }
      });
    }

    // Removing polygon on location change
    return () => {
      mapRef?.target?.eachLayer(function (layer) {
        if (layer.polygonLabel && layer.polygonLabel === "polygonGeoJson") {
          mapRef.target.removeLayer(layer);
        }
      });
    };
  }, [mapRef, polygonData]);

  return;
};
