import React from "react";
import JoyRideBackground from "../Media/Icons/JoyRideBg";
import JoyRideMapIcon from "../Media/Icons/JoyRideMapIcon";
import JoyRideDashboardIcon from "../Media/Icons/JoyRideDashboardIcon";
import JoyRideHelpIcon from "../Media/Icons/JoyRideHelpIcon";
import JoyRideSliderBg from "../Media/Images/JoyRideSliderBg.gif";

export const stepsInfo = [
  // Info page steps
  {
    target: ".map-icon",
    content: "Follow this guide to get a better overview of the application.",
    title: "Welcome to WeSea!",
    placement: "center",
    route: "info",
    background: <JoyRideBackground />,
    locale: {next: "Let's go!"},
    introElement: true,
  },
  {
    target: ".map-icon",
    content:
      "Map enables preview of available locations and various detections by our algorithm.",
    title: "Map",
    placement: "right",
    route: "info",
    locale: {back: "hidden"},
    icon: <JoyRideMapIcon />,
  },

  {
    target: ".restart-guide",
    content: (
      <>
        If you need aditional info about a feature, you can always restart the guide using
        this button! <br />
        If you want to close the guide just press &apos;x&apos; in upper right corner and
        we won&apos;t show it again!
      </>
    ),
    title: "Help",
    placement: "top",
    isLastStep: true,
    route: "info",
    locale: {back: "hidden"},
    icon: <JoyRideHelpIcon />,
  },
  {
    target: ".dashboard-icon",
    content: (
      <>
        Dashboard provides detailed analytics about each location and chosen time span.
        <br /> This feature is for authorized users only!
      </>
    ),
    title: "Dashboard",
    placement: "right",
    route: "info",
    locale: {back: "hidden"},
    icon: <JoyRideDashboardIcon />,
  },
];

export const stepsMap = [
  // Map route steps
  {
    target: ".location-select__control",
    content: (
      <>
        <div>
          Map gives you a lot of features i.e. to find, explore and compare coastal
          changes on different dates.
        </div>
        <div>Click the button bellow for a tour!</div>
      </>
    ),
    title: "Map",
    placement: "center",
    route: "map",
    disableBeacon: true,
    introElement: true,
    background: <JoyRideBackground />,
  },
  {
    target: ".location-select__control",
    content: "First step is to select your area of interest from a dropdown menu!",
    title: "Area of interest",
    placement: "left",
    spotlightClicks: true,
    route: "map",
    locale: {next: "hidden", back: "hidden"},
    icon: <JoyRideMapIcon />,
  },
  {
    target: ".wrapper-reference-date",
    content:
      "Each location has it's reference date. This date represents a satellite image from which all detections are calculated.",
    title: "Reference date",
    placement: "bottom",
    route: "map",
    disableBeacon: true,
    locale: {back: "hidden"},
    icon: <JoyRideMapIcon />,
  },
  {
    target: ".dates",
    content:
      "This is a list of available dates for which you can preview change detection imagery and associated detections.",
    title: "Available dates",
    placement: "bottom",
    route: "map",
    locale: {back: "hidden"},
    icon: <JoyRideMapIcon />,
  },
  {
    target: ".arrows",
    content: (
      <>
        Arrows enable scrolling through available dates, but dragging is also enabled!
        <br />
        When you click on desired date, related change detection map will be displayed.
      </>
    ),
    title: "Arrows",
    placement: "bottom",
    route: "map",
    locale: {back: "hidden"},
    icon: <JoyRideMapIcon />,
  },
  {
    target: ".parent-slider-btn",
    content:
      "To better examine coastal changes, use the slider to compare images side by side.",
    title: "Slider",
    placement: "left",
    route: "map",
    locale: {back: "hidden"},
    icon: <JoyRideMapIcon />,
  },
  {
    target: ".leaflet-control-zoom",
    content: (
      <>
        Use mouse scroll or these buttons to <i>control the zoom level.</i>
      </>
    ),
    title: "Zoom control",
    placement: "left",
    route: "map",
    locale: {back: "hidden"},
    icon: <JoyRideMapIcon />,
  },
  {
    target: ".position-download-btn",
    content: <>Display your approximate location on the map.</>,
    title: "Find me",
    placement: "left",
    route: "map",
    locale: {back: "hidden"},
    icon: <JoyRideMapIcon />,
  },
  {
    target: ".wrapper",
    content: (
      <>
        Base map is overlayed using change detection imagery. In case reference date is
        selected RGB image will be displayed. For other dates there is a heat-map styled
        change detection raster.
      </>
    ),
    title: "Imagery",
    placement: "top",
    route: "map",
    locale: {back: "hidden"},
    icon: <JoyRideMapIcon />,
  },
  {
    target: ".layer-control",
    content:
      "Switching between layers and toggling detections is enabled through layer control.",
    title: "Layer control",
    placement: "top",
    route: "map",
    locale: {back: "hidden"},
    icon: <JoyRideMapIcon />,
  },
];

export const stepsSlider = [
  // Slider steps
  {
    target: ".leaflet-container",
    content: (
      <>
        Slider enables comparison of two images of the same area on different dates.{" "}
        <br />
        Drag slider left or right to compare the images.
      </>
    ),
    title: "Imagery slider",
    placement: "center",
    route: "slider",
    introElement: true,
    background: <img src={JoyRideSliderBg} alt="loading..." />,
  },
  {
    target: ".header-slider",
    content: "Change imagery by selecting date you want to compare with reference date.",
    title: "Slider menu",
    placement: "bottom",
    route: "slider",
    locale: {back: "hidden"},
    icon: <JoyRideMapIcon />,
  },
];

export const stepsDashboard = [
  // DASHBOARD steps
  {
    target: ".location-filter",
    content: (
      <>
        Dashboard provides detailed analytics about detections and observed areas.
        <br />
        Click start to explore the features!
      </>
    ),
    title: "Dashboard",
    placement: "center",
    route: "dashboard",
    locale: {next: "Start"},
    icon: <JoyRideDashboardIcon />,
    introElement: true,
    background: <JoyRideBackground />,
  },
  {
    target: ".location-filter",
    content: "Select area of interest to display it's detections.",
    title: "Area of interest",
    placement: "bottom",
    route: "dashboard",
    spotlightClicks: true,
    locale: {next: "hidden", back: "hidden"},
    icon: <JoyRideDashboardIcon />,
  },
  {
    target: ".react-datepicker-wrapper",
    content: "For easier preview filter detections based on your preffered date range.",
    title: "Date range filter",
    placement: "bottom",
    route: "dashboard",
    locale: {next: "hidden", back: "hidden"},
    spotlightClicks: true,
    disableBeacon: true,
    icon: <JoyRideDashboardIcon />,
  },
  {
    target: ".detections-table",
    content: (
      <>
        Filtered detections and detailed data about each detection is listed in this
        table.
        <br />
        In upper right corner you can further filter data using search, and export only
        filtered data. <br />
        Clicking on position icon will display that detection on the map!
      </>
    ),
    title: "Detections table",
    placement: "right",
    route: "dashboard",
    disableBeacon: true,
    locale: {back: "hidden"},
    icon: <JoyRideDashboardIcon />,
  },
  {
    target: ".graph-container",
    content: (
      <>
        There are two graphs to better visualize detections throughout monitored range.
        <br />
        First there&apos;s a visualization of changes detected for each month.
        <br />
        And on the second graph, total area changed throughout each month.
      </>
    ),
    title: "Dashboard",
    placement: "left",
    route: "dashboard",
    locale: {back: "hidden"},
    icon: <JoyRideDashboardIcon />,
  },
];
